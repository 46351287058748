import { appAxios } from "../../api/axios";
import Button from "../../common/Button/Button";
import { sendCatchFeedback, sendFeedback } from "../../utils/feedback";
import { getUserSession } from "../../utils/userSession";
import { useAppDispatch } from "../../store/hooks";
import {
  closeLoadingIndicator,
  openLoadingIndicator,
} from "../../store/slices/loadingIndicator";
import { DevelopType } from "../../types";

function DeleteForm({
  selectedDevelop,
  closeDeleteModal,
  setDevelops,
  develops,
}: {
  selectedDevelop: DevelopType | { [key: string]: any };
  closeDeleteModal: () => void;
  setDevelops: (r: any) => void;
  develops: DevelopType[];
}) {
  const dispatch = useAppDispatch();

  const handleDelete = async () => {
    dispatch(openLoadingIndicator({ text: "Deleting Develop" }));
    const currentUser = getUserSession();

    try {
      const response = await appAxios.delete(
        "/develops/" + selectedDevelop._id,
        {
          headers: {
            Authorization: currentUser
              ? "Bearer " + currentUser?.token?.token
              : null,
          },
        }
      );
      sendFeedback(response.data?.status, "success");

      setDevelops(
        develops.filter((item: DevelopType) => item._id !== selectedDevelop._id)
      );
      closeDeleteModal();
    } catch (error) {
      sendCatchFeedback(error);
    }
    dispatch(closeLoadingIndicator());
  };

  return (
    <div>
      <p className="text-center md:text-left mb-10">
        You are trying to delete this Develop ({selectedDevelop?.title}). Are
        you sure you want to continue?
      </p>
      <div className="flex items-center justify-center gap-5 flex-wrap md:justify-start">
        <Button className="md:max-w-[200px] bg-error" onClick={handleDelete}>
          Yes, Delete
        </Button>
        <Button className="md:max-w-[200px] bg-dark" onClick={closeDeleteModal}>
          No, Cancel
        </Button>
      </div>
    </div>
  );
}

export default DeleteForm;
