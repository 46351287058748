import React from "react";
import Navbar from "./Navbar/Navbar";
import LoadingIndicator from "../common/LoadingIndicator/LoadingIndicator";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "./Sidebar/Sidebar";
import PageHeader from "../common/PageHeader/PageHeader";
import { useLocation } from "react-router";
import { capitalize } from "lodash";

type Props = {
  showPageHeader?: boolean;
  pageTitle?: string;
  pageAction?: React.ReactNode;
  children: React.ReactNode;
};

function AppLayout({
  showPageHeader = true,
  pageAction,
  pageTitle,
  children,
}: Props) {
  const location = useLocation();

  let CURRENT_LOCATION = location?.pathname?.includes("details")
    ? "Details"
    : // For nested routes that have IDs attached
      location?.pathname?.substring(
        location?.pathname?.lastIndexOf("/") + 1,
        location?.pathname?.length
      );

  return (
    <div className="max-h-screen">
      <Navbar />
      <div className="flex flex-row flex-nowrap">
        <aside>
          <Sidebar />
        </aside>
        <main className="w-full bg-[#F5F5F5] min-h-main mt-16 ">
          {showPageHeader && (
            <PageHeader
              title={pageTitle || capitalize(CURRENT_LOCATION)}
              action={pageAction}
            />
          )}

          <div className={showPageHeader ? "p-5" : ""}>{children}</div>
        </main>
      </div>
      <LoadingIndicator />
      <ToastContainer
        style={{
          fontSize: 16,
          zIndex: 30,
        }}
        theme="colored"
        autoClose={5000}
        position="top-right"
        hideProgressBar={true}
        closeOnClick={true}
      />
    </div>
  );
}

export default AppLayout;
