import React from 'react';
import CSVImage from '../../../assets/icons/csv.svg';
import { CSVLink, CSVDownload } from "react-csv";

function CSVButton({ data }: any) {
    console.log(data);
    const headers = [
        {label: "Email", key: 'email'},
        {label: "First Name", key: 'first_name'},
        {label: "Middle Name", key: 'middle_name'},
        {label: "Last Name", key: 'last_name'},
        {label: "Phone Number", key: 'phone_number'},
        {label: "Primary Source Of Income", key: 'primary_source_of_income'},
        {label: "Created", key: 'created_at'},
        {label: "State", key: 'state_of_residence'},
        {label: "Local Government", key: 'local_government'},       
        {label: "Occupation", key: 'occupation'},
        {label: "Title", key: 'grow.title'},
        {label: "Property Name", key: 'grow.property_name'},
        {label: "Description", key: 'grow.description'},
        {label: "Current Price", key: 'grow.current_price'},
        {label: "Address", key: 'grow.address'},
        {label: "Format", key: 'grow.format'},
        {label: "Size", key: 'grow.size'},





    ]
    return (


        <CSVLink data={data} filename={"grow-requests.csv"} headers={headers}>
            <button
                className='p-2 pl-5 pr-5 hover:bg-dark bg-lightDark rounded flex gap-2 text-white'
            >
                <img src={CSVImage} alt='' />CSV
            </button>

        </CSVLink>
    );
}

export default CSVButton;
