import { InvestType, UnitType } from "../../types";
import { formatNumberToNaira } from "../../utils/stringManipulations";

function ViewInvest({
  selectedInvest,
}: {
  selectedInvest: InvestType | { [key: string]: any };
}) {
  console.log(selectedInvest);
  return (
    <div>
      <img
        src={selectedInvest.images[0]}
        alt="Develop"
        className="rounded-md h-44 w-full object-cover"
      />
      <p className="mt-5  text-2xl">
        {selectedInvest.title} - {selectedInvest.property_name}
      </p>
      <p className=" mb-10 text-xs text-gray-500">
        {selectedInvest.address}, {selectedInvest.city}, {selectedInvest.state}
      </p>
      <p className="mb-5 text-md">{selectedInvest.description}</p>
      <p className="mb-5 text-md capitalize">
        Type: {selectedInvest.property_type}
      </p>
      {selectedInvest.type === "house" && (
        <div className="mb-5 border p-2 rounded">
          <p className="text-lg font-bold mb-2">Units:</p>
          {selectedInvest.units?.map((unit: UnitType, index: number) => (
            <div className="flex flex-col gap-2 mb-4" key={unit._id}>
              <span>{index + 1}</span>
              <span>Title: {unit.title}</span>
              <span>
                Current Price: {formatNumberToNaira(unit.current_price)}
              </span>
              <span>
                Previous Price: {formatNumberToNaira(unit.previous_price || 0)}
              </span>
            </div>
          ))}
        </div>
      )}
      <p className="mb-5 text-md">Property ID: {selectedInvest.property_id}</p>
      <p className="mb-5 text-md">Size: {selectedInvest.size}</p>
      <p className="mb-5 text-md">
        Current price: {formatNumberToNaira(selectedInvest.current_price)}
      </p>
      <p className="mb-5 text-md">
        Previous price: {formatNumberToNaira(selectedInvest.previous_price)}
      </p>
      <p className="mb-5 text-md">
        Discount: {formatNumberToNaira(selectedInvest.discount)}
      </p>
      <p className="mb-5 text-md">
        Neighborhood Features: <br /> {selectedInvest.neighborhood?.join(", ")}
      </p>
      <p className="mb-10 text-md">
        Estate Features: <br /> {selectedInvest.estate_features?.join(", ")}
      </p>

      <p className="mb-2 text-sm">Images</p>
      <div className="grid grid-cols-2 gap-5">
        {selectedInvest.images?.map((image: string) => (
          <img
            src={image}
            alt=""
            className="rounded-md h-32 w-full object-cover"
            key={image}
          />
        ))}
      </div>
    </div>
  );
}

export default ViewInvest;
