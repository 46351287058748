import { useEffect, useState } from "react";
import { appAxios } from "../../api/axios";
import Pagination from "../../common/Pagination";
import { sendCatchFeedback } from "../../utils/feedback";
import { getUserSession } from "../../utils/userSession";
import AppLayout from "../../layout/AppLayout";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  closeLoadingIndicator,
  openLoadingIndicator,
} from "../../store/slices/loadingIndicator";
import ThriftList from "../../components/Thrift/ThriftList";

function Thrift() {
  const [thrifts, setThrifts] = useState<any[]>([]);
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const { limit } = useAppSelector((state) => state.pageLimit);

  useEffect(() => {
    const getThrifts = async () => {
      const currentUser = getUserSession();

      dispatch(openLoadingIndicator({ text: "Fetching Thrifts Data" }));
      try {
        const response = await appAxios.get(
          `/thrift/search?page=${page}&take=${limit}`,
          {
            headers: {
              Authorization: currentUser
                ? "Bearer " + currentUser?.token?.token
                : null,
            },
          }
        );
        setThrifts(response.data?.data?.thrift?.data);
        setTotalResults(response.data?.data?.thrift?.meta?.itemCount);
      } catch (error) {
        sendCatchFeedback(error);
      }
      dispatch(closeLoadingIndicator());
    };
    getThrifts();
  }, [dispatch, page, limit]);

  const nextPage = async () => {
    setPage(page + 1);
  };
  const previousPage = async () => {
    setPage(page - 1);
  };

  return (
    <AppLayout>
      <ThriftList list={thrifts} />
      {thrifts.length > 0 && (
        <Pagination
          page={page}
          totalResults={totalResults}
          nextPageChange={nextPage}
          previousPageChange={previousPage}
        />
      )}
    </AppLayout>
  );
}

export default Thrift;
