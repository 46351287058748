
import DevPro from '../../pages/DevPro/Index';
import { checkPrivateRoute } from '../utils';

export const devProRoutes = [
	{
		path: '/devpro',
		element: <DevPro />,
		loader: checkPrivateRoute,
	},
	
];
