import React from "react";
import { sendCatchFeedback } from "../utils/feedback";
import AppLayout from "../layout/AppLayout";
import { useAppDispatch } from "../store/hooks";
import {
  closeLoadingIndicator,
  openLoadingIndicator,
} from "../store/slices/loadingIndicator";
import { DevelopResultType, GrowResultType, UserResultType } from "../types";
import { appAxios } from "../api/axios";
import { getUserSession } from "../utils/userSession";

import DashboardChartItem from "../components/Dashboard/DashboardChartItem";
import DashboardSummaryCard from "../components/Dashboard/DashboardSummaryCard";

function Dashboard() {
  const [develops, setDevelops] = React.useState<DevelopResultType>({
    data: [],
    meta: {},
  });

  const [users, setUsers] = React.useState<UserResultType>({
    data: [],
    meta: {},
  });

  const [grow, setGrow] = React.useState<GrowResultType>({
    data: [],
    meta: {},
  });

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    const getData = async () => {
      const currentUser = getUserSession();

      try {
        dispatch(openLoadingIndicator({ text: "Fetching Develops" }));
        const developResponse = await appAxios.get("/develops", {
          headers: {
            Authorization: currentUser
              ? "Bearer " + currentUser?.token?.token
              : null,
          },
        });
        setDevelops(developResponse.data?.data?.develops);

        dispatch(openLoadingIndicator({ text: "Fetching Invest" }));
        const investResponse = await appAxios.get("/develops", {
          headers: {
            Authorization: currentUser
              ? "Bearer " + currentUser?.token?.token
              : null,
          },
        });
        setDevelops(investResponse.data?.data?.develops);

        dispatch(openLoadingIndicator({ text: "Fetching Users" }));
        const userResponse = await appAxios.get("/users", {
          headers: {
            Authorization: currentUser
              ? "Bearer " + currentUser?.token?.token
              : null,
          },
        });
        setUsers(userResponse.data?.data?.users);

        dispatch(openLoadingIndicator({ text: "Fetching Grow Products" }));
        const requestsResponse = await appAxios.get("/grows", {
          headers: {
            Authorization: currentUser
              ? "Bearer " + currentUser?.token?.token
              : null,
          },
        });
        setGrow(requestsResponse.data?.data?.grows);
      } catch (error) {
        sendCatchFeedback(error);
      }
      dispatch(closeLoadingIndicator());
    };
    getData();
  }, [dispatch]);
  return (
    <AppLayout>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
        <DashboardChartItem
          title="Grow"
          color="#17B28D"
          value={grow?.meta?.itemCount || 0}
          destination="/grow"
        />
        <DashboardChartItem
          title="Invest"
          color="#F48120"
          value={grow?.meta?.itemCount || 0}
          destination="/invest"
        />
        <DashboardChartItem
          title="Develop"
          color="#D14343"
          value={develops?.meta?.itemCount || 0}
          destination="/develops"
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-6">
        <DashboardSummaryCard
          color="#17B28D"
          title="Total Properties"
          value={
            (develops?.meta?.itemCount || 0) + (grow?.meta?.itemCount || 0)
          }
        />
        <DashboardSummaryCard
          color="#F48120"
          title="Total Users"
          value={users?.meta?.itemCount || 0}
        />
      </div>
    </AppLayout>
  );
}

export default Dashboard;
