import React from "react";
import Card from "../../../common/Card/Card";
import { InvestRequestType } from "../../../types";

function RequestInvestCard({ request }: { request: InvestRequestType }) {
  return (
    <Card className="min-w-full w-full">
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="border-b-2 md:border-b-0 md:border-r-2 border-lightGrey md:pr-5 mb-5 md:mb-0 pb-5 md:pb-0">
          <p className="mb-5 text-black font-medium text-lg mt-2 md:mt-0">
            Request
          </p>
          <div className="flex flex-row items-center flex-wrap justify-between mb-2 text-black text-sm">
            <b>Name</b>
            <span className="capitalize">{request.name}</span>
          </div>
          <div className="flex flex-row items-center flex-wrap justify-between mb-2 text-black text-sm">
            <b>Email</b>
            <span>{request.email}</span>
          </div>
          <div className="flex flex-row items-center flex-wrap justify-between mb-2 text-black text-sm">
            <b>Phone</b>
            <span>{request.phone_number}</span>
          </div>
          {/* <div className='flex flex-row items-center flex-wrap justify-between mb-2 text-black text-sm'>
						<b>Inspection</b>
						<span className='capitalize'>{request.inspection}</span>
					</div> */}
          <div className="flex flex-row items-center flex-wrap justify-between mb-2 text-black text-sm">
            <b>Quantity</b>
            <span>{request.quantity}</span>
          </div>
          <div className="flex flex-row items-center flex-wrap justify-between mb-2 text-black text-sm">
            <b>Date</b>
            <span>{new Date(request.created_at).toDateString()}</span>
          </div>
        </div>
        <div className="md:pl-5">
          <p className="mb-5 text-black font-medium text-lg mt-2 md:mt-0">
            Invest
          </p>
          <img
            src={request.invest.images[0]}
            alt=""
            className="h-32 w-full object-cover rounded-md mb-2"
          />
          <p className="mb-2 text-black text-sm text-md">
            {request.invest.title} - {request.invest.property_name}
          </p>
          <p className="text-gray-400 text-xs">
            {request.invest.address}, {request.invest.state}
          </p>
        </div>
      </div>
    </Card>
  );
}

export default RequestInvestCard;
