import { appAxios } from "../../api/axios";
import LabelInput from "../../common/LabelInput/LabelInput";
import { sendCatchFeedback, sendFeedback } from "../../utils/feedback";
import {
  closeLoadingIndicator,
  openLoadingIndicator,
} from "../../store/slices/loadingIndicator";
import { useFormik } from "formik";
import * as yup from "yup";
import { useAppDispatch } from "../../store/hooks";
import { useNavigate } from "react-router";
import Button from "../../common/Button/Button";
import Dropdown from "../../common/Dropdown/Dropdown";
import { DevelopType, UnitType } from "../../types";
import { getUserSession } from "../../utils/userSession";
import { useState } from "react";
import HouseUnits from "./HouseUnits";

function EditForm({
  selectedDevelop,
  closeEditModal,
  setDevelops,
  develops,
}: {
  selectedDevelop: DevelopType | { [key: string]: any };
  closeEditModal: () => void;
  setDevelops: (r: any) => void;
  develops: DevelopType[];
}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      title: selectedDevelop.title,
      description: selectedDevelop.description,
      property_id: selectedDevelop.property_id,
      propertyName: selectedDevelop.property_name,
      state: selectedDevelop.state,
      city: selectedDevelop.city,
      address: selectedDevelop.address,
      neighborhood: selectedDevelop.neighborhood?.join(","),
      estateFeatures: selectedDevelop.estate_features?.join(","),
      format: selectedDevelop.format,
      size: selectedDevelop.size,
      currentPrice: selectedDevelop.current_price,
      previousPrice: selectedDevelop.previous_price,
      type: selectedDevelop.type,
      discount: selectedDevelop.discount,
      propertyType: selectedDevelop.propertyType,
    },
    onSubmit: () => {
      submitValues();
    },
    validationSchema: yup.object({
      title: yup.string().required("Required"),
      property_id: yup.string().required("Required"),
      propertyName: yup.string().required("Required"),
      state: yup.string().required("Required"),
      city: yup.string().required("Required"),
      address: yup.string().required("Required"),
      neighborhood: yup.string().required("Required"),
      format: yup.string().required("Required"),
      estateFeatures: yup.string().required("Required"),
      size: yup.string().required("Required"),
      currentPrice: yup.number().required("Required"),
      propertyType: yup.string().required("Required"),
    }),
  });

  const [units, setUnits] = useState(
    selectedDevelop.units?.reduce(
      (initial: any, unit: UnitType) => ({
        ...initial,
        [unit._id]: { ...unit, id: unit._id },
      }),
      {}
    )
  );

  const submitValues = async () => {
    let unitError = Object.values(units)?.find(
      (unit: any) =>
        unit?.title === "" || unit?.current_price?.toString() === ""
    );

    if (unitError && formik.values.type === "house") {
      return sendFeedback(
        "Title and current price are required for each house unit",
        "error"
      );
    }
    const data = {
      title: formik.values.title,
      description: formik.values.description,
      property_id: formik.values.property_id,
      property_name: formik.values.propertyName,
      address: formik.values.address,
      city: formik.values.city,
      state: formik.values.state,
      neighborhood: formik.values.neighborhood
        .split(",")
        ?.map((element: string) => element?.trim()),
      estate_features: formik.values.estateFeatures
        .split(",")
        ?.map((element: string) => element?.trim()),
      size: formik.values.size,
      previous_price: formik.values.previousPrice,
      format: formik.values.format,
      current_price: formik.values.currentPrice,
      type: formik.values.type,
      discount: formik.values.discount,
      units: formik.values.type === "house" ? Object.values(units) : undefined,
    };

    const currentUser = getUserSession();

    dispatch(openLoadingIndicator({ text: "Updating Develop" }));
    try {
      const response = await appAxios.patch(
        "/develops/" + selectedDevelop._id,
        data,
        {
          headers: {
            Authorization: currentUser
              ? "Bearer " + currentUser?.token?.token
              : null,
          },
        }
      );
      sendFeedback(response.data?.status, "success");

      // Update state
      setDevelops(
        develops.map((item: DevelopType) => {
          if (item._id === selectedDevelop._id) {
            item.title = formik.values.title;
            item.description = formik.values.description;
            item.property_id = formik.values.property_id;
            item.property_name = formik.values.propertyName;
            item.address = formik.values.address;
            item.city = formik.values.city;
            item.state = formik.values.state;
            item.neighborhood = formik.values.neighborhood
              .split(",")
              ?.map((element: string) => element?.trim());
            item.estate_features = formik.values.estateFeatures
              .split(",")
              ?.map((element: string) => element?.trim());
            item.size = formik.values.size;
            item.format = formik.values.format;
            item.previous_price = formik.values.previousPrice;
            item.current_price = formik.values.currentPrice;
            item.type = formik.values.type;
            item.discount = formik.values.discount;
            item.units =
              formik.values.type === "house" ? Object.values(units) : undefined;
          }
          return item;
        })
      );
      closeEditModal();

      navigate("/develops");
    } catch (error) {
      sendCatchFeedback(error);
    }
    dispatch(closeLoadingIndicator());
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <LabelInput
          formik={formik}
          name="title"
          label="Title"
          className="mb-5"
        />
        <LabelInput
          formik={formik}
          name="description"
          label="Description"
          className="mb-5"
        />
        <LabelInput
          formik={formik}
          name="property_id"
          label="Property ID"
          className="mb-5"
        />
        <LabelInput
          formik={formik}
          name="propertyName"
          label="Property Name"
          className="mb-5"
        />

        <Dropdown
          values={[
            {
              label: "Prime",
              value: "prime",
            },
            {
              label: "Luxury",
              value: "luxury",
            },
          ]}
          label="Format"
          name="format"
          defaultValue={{
            label: formik.values.format,
            value: formik.values.format,
          }}
          formik={formik}
          className="mb-5"
        />
        <Dropdown
          values={[
            {
              label: "House",
              value: "house",
            },
            {
              label: "Land",
              value: "land",
            },
          ]}
          label="Type"
          name="type"
          defaultValue={{
            label: formik.values.type,
            value: formik.values.type,
          }}
          formik={formik}
          className="mb-5"
        />

        {formik.values.type === "house" && (
          <HouseUnits units={units} setUnits={setUnits} />
        )}
        <LabelInput formik={formik} name="size" label="Size" className="mb-5" />
        <LabelInput
          formik={formik}
          name="address"
          label="Address"
          className="mb-5"
        />
        <LabelInput formik={formik} name="city" label="City" className="mb-5" />
        <LabelInput
          formik={formik}
          name="state"
          label="State"
          className="mb-5"
        />
        <Dropdown
          values={[
            {
              label: "Residencial",
              value: "residencial",
            },
            {
              label: "Commercial",
              value: "commercial",
            },
            {
              label: "Both",
              value: "both",
            },
          ]}
          label="Property Type"
          name="propertyType"
          defaultValue={formik.values.propertyType}
          formik={formik}
          className="mb-5"
        />
        <LabelInput
          formik={formik}
          name="currentPrice"
          label="Current Price"
          type="number"
          className="mb-5"
        />
        <LabelInput
          formik={formik}
          name="previousPrice"
          label="Previous Price"
          type="number"
          className="mb-5"
        />
        <LabelInput
          formik={formik}
          name="discount"
          label="Discount"
          type="number"
          className="mb-5"
        />
        <LabelInput
          formik={formik}
          name="neighborhood"
          label="Neighborhood"
          className="mb-5"
          hint="Separate items by comma"
        />
        <LabelInput
          formik={formik}
          name="estateFeatures"
          label="Estate Features"
          className="mb-10"
          hint="Separate items by comma"
        />

        <Button type="submit">Update Develop</Button>
      </form>
    </>
  );
}

export default EditForm;
