import React from "react";
import { GrowType } from "../../types";
import GrowCard from "./GrowCard";

function GrowList({
  grow,
  openDeleteModal,
  openViewModal,
  openEditModal,
}: {
  grow: GrowType[];
  openDeleteModal: (d: GrowType) => void;
  openEditModal: (d: GrowType) => void;
  openViewModal: (d: GrowType) => void;
}) {
  if (!grow.length)
    return <span className="text-black">No grow product found</span>;
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 w-full gap-5 bg-white p-5 rounded">
      {grow.map((grow) => (
        <GrowCard
          grow={grow}
          key={grow._id}
          openDeleteModal={openDeleteModal}
          openViewModal={openViewModal}
          openEditModal={openEditModal}
        />
      ))}
    </div>
  );
}

export default GrowList;
