import React from "react";
import { UserType } from "../../types";
import ContactCard from "./ContactCard";

function ContactList({ list }: { list: Record<string, string>[] }) {
  return (
    <div className="flex flex-col gap-5">
      {list.length > 0 ? (
        list.map((info: Record<string, string>) => (
          <ContactCard data={info} key={info._id} />
        ))
      ) : (
        <span className="text-black">No Message found</span>
      )}
    </div>
  );
}

export default ContactList;
