import { useFormik } from "formik";
import { appAxios } from "../../../api/axios";
import LabelInput from "../../../common/LabelInput/LabelInput";
import { sendCatchFeedback, sendFeedback } from "../../../utils/feedback";
import {
  closeLoadingIndicator,
  openLoadingIndicator,
} from "../../../store/slices/loadingIndicator";
import * as yup from "yup";
import { useAppDispatch } from "../../../store/hooks";
import { useNavigate } from "react-router-dom";
import Button from "../../../common/Button/Button";
import { GrowType } from "../../../types";
import { getUserSession } from "../../../utils/userSession";
import Dropdown from "../../../common/Dropdown/Dropdown";

function EditInvestPropertyForm({
  selectedInvestProperty,
  closeEditModal,
  setInvestProperty,
  investProperty,
}: {
  selectedInvestProperty: GrowType | { [key: string]: any };
  closeEditModal: () => void;
  setInvestProperty: (r: any) => void;
  investProperty: GrowType[];
}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      title: selectedInvestProperty.title,
      description: selectedInvestProperty.description,
      propertyName: selectedInvestProperty.property_name,
      state: selectedInvestProperty.state,
      city: selectedInvestProperty.city,
      address: selectedInvestProperty.address,
      features: selectedInvestProperty.features?.join(","),
      size: selectedInvestProperty.size,
      currentPrice: selectedInvestProperty.current_price,
      previousPrice: selectedInvestProperty.previous_price,
      discount: selectedInvestProperty.discount,
      format: selectedInvestProperty.format,
      interest: selectedInvestProperty.interest,
      equity: selectedInvestProperty.equity,
      mortgage_type: selectedInvestProperty.mortgage_type,
      repayment_duration: selectedInvestProperty.repayment_duration,
    },
    onSubmit: () => {
      submitValues();
    },
    validationSchema: yup.object({
      title: yup.string().required("Required"),
      propertyName: yup.string().required("Required"),
      state: yup.string().required("Required"),
      city: yup.string().required("Required"),
      address: yup.string().required("Required"),
      features: yup.string().required("Required"),
      size: yup.string().required("Required"),
      currentPrice: yup.number().required("Required"),
      mortgage_type: yup.string().required("Required"),
      format: yup.string().required("Required"),
      interest: yup.number().required("Required"),
      equity: yup.number().required("Required"),
      repayment_duration: yup.string().required("Required"),
    }),
  });

  const submitValues = async () => {
    const data = {
      title: formik.values.title,
      description: formik.values.description,
      property_name: formik.values.propertyName,
      address: formik.values.address,
      city: formik.values.city,
      state: formik.values.state,
      features: formik.values.features
        .split(",")
        ?.map((element: string) => element?.trim()),
      size: formik.values.size,
      previous_price: formik.values.previousPrice,
      current_price: formik.values.currentPrice,
      discount: formik.values.discount,
      format: formik.values.format,
      interest: formik.values.interest,
      equity: formik.values.equity,
      repayment_duration: formik.values.repayment_duration,
      mortgage_type: formik.values.mortgage_type,
    };

    const currentUser = getUserSession();

    dispatch(openLoadingIndicator({ text: "Updating Grow" }));
    try {
      const response = await appAxios.patch(
        "/grows/" + selectedInvestProperty._id,
        data,
        {
          headers: {
            Authorization: currentUser
              ? "Bearer " + currentUser?.token?.token
              : null,
          },
        }
      );
      sendFeedback(response.data?.status, "success");

      // Update state
      setInvestProperty(
        investProperty.map((item: GrowType) => {
          if (item._id === selectedInvestProperty._id) {
            item.title = formik.values.title;
            item.description = formik.values.description;
            item.property_name = formik.values.propertyName;
            item.address = formik.values.address;
            item.city = formik.values.city;
            item.state = formik.values.state;
            item.features = formik.values.features
              .split(",")
              ?.map((element: string) => element?.trim());
            item.size = formik.values.size;
            item.previous_price = formik.values.previousPrice;
            item.current_price = formik.values.currentPrice;
            item.discount = formik.values.discount;
            item.format = formik.values.format;
            item.interest = formik.values.interest;
            item.equity = formik.values.equity;
            item.repayment_duration = formik.values.repayment_duration;
            item.mortgage_type = formik.values.mortgage_type;
          }
          return item;
        })
      );
      closeEditModal();

      navigate("/products");
    } catch (error) {
      sendCatchFeedback(error);
    }
    dispatch(closeLoadingIndicator());
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <LabelInput
          formik={formik}
          name="title"
          label="Title"
          className="mb-5"
        />
        <LabelInput
          formik={formik}
          name="description"
          label="Description"
          className="mb-5"
        />

        <LabelInput
          formik={formik}
          name="propertyName"
          label="Property Name"
          className="mb-5"
        />
        <Dropdown
          values={[
            {
              label: "Extra",
              value: "extra",
            },
            {
              label: "Beta",
              value: "beta",
            },
          ]}
          label="Type"
          name="mortgage_type"
          defaultValue={{
            label: formik.values.mortgage_type,
            value: formik.values.mortgage_type,
          }}
          formik={formik}
          className="mb-5"
        />

        <Dropdown
          values={[
            {
              label: "Prime",
              value: "prime",
            },
            {
              label: "Luxury",
              value: "luxury",
            },
          ]}
          label="Format"
          name="format"
          defaultValue={{
            label: formik.values.format,
            value: formik.values.format,
          }}
          formik={formik}
          className="mb-5"
        />

        <LabelInput
          formik={formik}
          name="interest"
          label="Interest"
          type="number"
          className="mb-5"
        />
        <LabelInput
          formik={formik}
          name="equity"
          label="Equity"
          type="number"
          className="mb-5"
        />
        <LabelInput
          formik={formik}
          name="repayment_duration"
          label="Repayment Duration"
          className="mb-5"
        />

        <LabelInput formik={formik} name="size" label="Size" className="mb-5" />
        <LabelInput
          formik={formik}
          name="address"
          label="Address"
          className="mb-5"
        />
        <LabelInput formik={formik} name="city" label="City" className="mb-5" />
        <LabelInput
          formik={formik}
          name="state"
          label="State"
          className="mb-5"
        />
        <LabelInput
          formik={formik}
          name="currentPrice"
          label="Current Price"
          type="number"
          className="mb-5"
        />
        <LabelInput
          formik={formik}
          name="previousPrice"
          label="Previous Price"
          type="number"
          className="mb-5"
        />
        <LabelInput
          formik={formik}
          name="discount"
          label="Discount"
          type="number"
          className="mb-5"
        />

        <LabelInput
          formik={formik}
          name="features"
          label="Features"
          className="mb-10"
          hint="Separate items by comma"
        />

        <Button type="submit">Update Invest Property Product</Button>
      </form>
    </>
  );
}

export default EditInvestPropertyForm;
