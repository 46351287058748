import Develops from '../../pages/Develops';
import AddDevelop from '../../pages/Develops/add';
import { checkPrivateRoute } from '../utils';

export const developsRoutes = [
	{
		path: '/develops',
		element: <Develops />,
		loader: checkPrivateRoute,
	},
	{
		path: '/develops/add',
		element: <AddDevelop />,
		loader: checkPrivateRoute,
	},
];
