import React from "react";
import Card from "../../../common/Card/Card";
import { GrowRequestType } from "../../../types";

function RequestCard({ request }: { request: GrowRequestType }) {
  return (
    <Card className="min-w-full w-full">
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="border-b-2 md:border-b-0 md:border-r-2 border-lightGrey md:pr-5 mb-5 md:mb-0 pb-5 md:pb-0">
          <p className="mb-5 text-black font-medium text-lg mt-2 md:mt-0">
            Request
          </p>
          <div className="flex flex-row items-center flex-wrap justify-between mb-2 text-black text-sm">
            <b>Name</b>
            <span className="capitalize">
              {request.first_name} {request.last_name}{" "}
            </span>
          </div>
          <div className="flex flex-row items-center flex-wrap justify-between mb-2 text-black text-sm">
            <b>Email</b>
            <span>{request.email}</span>
          </div>
          <div className="flex flex-row items-center flex-wrap justify-between mb-2 text-black text-sm">
            <b>Phone</b>
            <span>{request.phone_number}</span>
          </div>
          <div className="flex flex-row items-center flex-wrap justify-between mb-2 text-black text-sm">
            <b>Occupation</b>
            <span className="capitalize">{request.occupation}</span>
          </div>
          <div className="flex flex-row items-center flex-wrap justify-between mb-2 text-black text-sm">
            <b>LGA</b>
            <span>{request.local_government}</span>
          </div>
          <div className="flex flex-row items-center flex-wrap justify-between mb-2 text-black text-sm">
            <b>State</b>
            <span>{request.state_of_residence}</span>
          </div>
          <div className="flex flex-row items-center flex-wrap justify-between mb-2 text-black text-sm">
            <b>Sent</b>
            <span>{new Date(request.created_at).toDateString()} </span>
          </div>
        </div>
        <div className="md:pl-5">
          <p className="mb-5 text-black font-medium text-lg mt-2 md:mt-0">
            Grow
          </p>
          <img
            src={request.grow?.images[0]}
            alt=""
            className="h-32 w-full object-cover rounded-md mb-2"
          />
          <p className="mb-2 text-black text-sm text-md">
            {request.grow?.title} - {request.grow?.property_name}
          </p>
          <p className="text-gray-400 text-xs">
            {request.grow?.address}, {request.grow?.state}
          </p>
        </div>
      </div>
    </Card>
  );
}

export default RequestCard;
