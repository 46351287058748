import { appAxios } from "../../api/axios";
import LabelInput from "../../common/LabelInput/LabelInput";
import { sendCatchFeedback, sendFeedback } from "../../utils/feedback";
import {
  closeLoadingIndicator,
  openLoadingIndicator,
} from "../../store/slices/loadingIndicator";
import { useFormik } from "formik";
import * as yup from "yup";
import { useAppDispatch } from "../../store/hooks";
import { useNavigate } from "react-router";
import Button from "../../common/Button/Button";
import Dropdown from "../../common/Dropdown/Dropdown";
import { InvestType, UnitType } from "../../types";
import { getUserSession } from "../../utils/userSession";
import { useState } from "react";
import HouseUnits from "./HouseUnits";

function EditInvestForm({
  selectedInvest,
  closeEditModal,
  setInvests,
  invests,
}: {
  selectedInvest: InvestType | { [key: string]: any };
  closeEditModal: () => void;
  setInvests: (r: any) => void;
  invests: InvestType[];
}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      title: selectedInvest.title,
      description: selectedInvest.description,
      property_id: selectedInvest.property_id,
      propertyName: selectedInvest.property_name,
      appreciation_rate: selectedInvest.appreciation_rate,
      total_bundles: selectedInvest.total_bundles,
      return_of_investment: selectedInvest.return_of_investment,
      format: selectedInvest.format,
      state: selectedInvest.state,
      city: selectedInvest.city,
      address: selectedInvest.address,
      neighborhood: selectedInvest.neighborhood?.join(","),
      estateFeatures: selectedInvest.estate_features?.join(","),
      size: selectedInvest.size,
      currentPrice: selectedInvest.current_price,
      previousPrice: selectedInvest.previous_price,
      type: selectedInvest.type,
      discount: selectedInvest.discount,
    },
    onSubmit: () => {
      submitValues();
    },
    validationSchema: yup.object({
      title: yup.string().required("Required"),
      property_id: yup.string().required("Required"),
      propertyName: yup.string().required("Required"),
      state: yup.string().required("Required"),
      city: yup.string().required("Required"),
      address: yup.string().required("Required"),
      format: yup.string().required("Required"),
      neighborhood: yup.string().required("Required"),
      estateFeatures: yup.string().required("Required"),
      size: yup.string().required("Required"),
      currentPrice: yup.number().required("Required"),
      return_of_investment: yup.number().required("Required"),
      total_bundles: yup.number().required("Required"),
      appreciation_rate: yup.string().required("Required"),
    }),
  });

  const [units, setUnits] = useState(
    selectedInvest.units?.reduce(
      (initial: any, unit: UnitType) => ({
        ...initial,
        [unit._id]: { ...unit, id: unit._id },
      }),
      {}
    )
  );

  const submitValues = async () => {
    // let unitError = Object.values(units)?.find((unit: any) => unit?.title === "" || unit?.current_price?.toString() === "")

    // if (unitError && formik.values.type === 'house') {
    // 	return sendFeedback("Title and current price are required for each house unit", 'error');
    // }
    const data = {
      title: formik.values.title,
      description: formik.values.description,
      property_id: formik.values.property_id,
      property_name: formik.values.propertyName,
      address: formik.values.address,
      city: formik.values.city,
      state: formik.values.state,
      appreciation_rate: formik.values.appreciation_rate,
      return_of_investment: formik.values.return_of_investment,
      total_bundles: formik.values.total_bundles,
      format: formik.values.format,
      neighborhood: formik.values.neighborhood.split(","),
      estate_features: formik.values.estateFeatures.split(","),
      size: formik.values.size,
      previous_price: formik.values.previousPrice,
      current_price: formik.values.currentPrice,
      type: formik.values.type,
      discount: formik.values.discount,
      units: formik.values.type === "house" ? Object.values(units) : undefined,
    };

    const currentUser = getUserSession();

    dispatch(openLoadingIndicator({ text: "Updating Develop" }));
    try {
      const response = await appAxios.patch(
        "/invests/" + selectedInvest._id,
        data,
        {
          headers: {
            Authorization: currentUser
              ? "Bearer " + currentUser?.token?.token
              : null,
          },
        }
      );
      sendFeedback(response.data?.status, "success");

      // Update state
      setInvests(
        invests.map((item: InvestType) => {
          if (item._id === selectedInvest._id) {
            item.title = formik.values.title;
            item.description = formik.values.description;
            item.property_id = formik.values.property_id;
            item.property_name = formik.values.propertyName;
            item.format = formik.values.format;
            item.appreciation_rate = formik.values.appreciation_rate;
            item.total_bundles = formik.values.total_bundles;
            item.return_of_investment = formik.values.return_of_investment;
            item.address = formik.values.address;
            item.city = formik.values.city;
            item.state = formik.values.state;
            item.neighborhood = formik.values.neighborhood.split(",");
            item.estate_features = formik.values.estateFeatures.split(",");
            item.size = formik.values.size;
            item.previous_price = formik.values.previousPrice;
            item.current_price = formik.values.currentPrice;
            item.type = formik.values.type;
            item.discount = formik.values.discount;
            item.units =
              formik.values.type === "house" ? Object.values(units) : undefined;
          }
          return item;
        })
      );
      closeEditModal();

      navigate("/invest");
    } catch (error) {
      sendCatchFeedback(error);
    }
    dispatch(closeLoadingIndicator());
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <LabelInput
          formik={formik}
          name="title"
          label="Title"
          className="mb-5"
        />
        <LabelInput
          formik={formik}
          name="description"
          label="Description"
          className="mb-5"
        />
        <LabelInput
          formik={formik}
          name="property_id"
          label="Property ID"
          className="mb-5"
        />
        <LabelInput
          formik={formik}
          name="propertyName"
          label="Property Name"
          className="mb-5"
        />

        <Dropdown
          values={[
            {
              label: "House",
              value: "house",
            },
            {
              label: "Land",
              value: "land",
            },
          ]}
          label="Type"
          name="type"
          defaultValue={{
            label: formik.values.type,
            value: formik.values.type,
          }}
          formik={formik}
          className="mb-5"
        />

        {formik.values.type === "house" && (
          <HouseUnits units={units} setUnits={setUnits} />
        )}
        <LabelInput formik={formik} name="size" label="Size" className="mb-5" />
        <LabelInput
          formik={formik}
          name="address"
          label="Address"
          className="mb-5"
        />
        <LabelInput formik={formik} name="city" label="City" className="mb-5" />
        <LabelInput
          formik={formik}
          name="state"
          label="State"
          className="mb-5"
        />
        <Dropdown
          values={[
            {
              label: "Luxury",
              value: "luxury",
            },
            {
              label: "Prime",
              value: "prime",
            },
            {
              label: "Bundle",
              value: "bundle",
            },
          ]}
          label="Format"
          name="format"
          defaultValue={formik.values.format}
          formik={formik}
          className="mb-5"
        />
        <LabelInput
          formik={formik}
          name="currentPrice"
          label="Current Price"
          type="number"
          className="mb-5"
        />
        <LabelInput
          formik={formik}
          name="previousPrice"
          label="Previous Price"
          type="number"
          className="mb-5"
        />
        <LabelInput
          formik={formik}
          name="discount"
          label="Discount"
          type="number"
          className="mb-5"
        />
        <LabelInput
          formik={formik}
          name="appreciation_rate"
          label="Appreciation_rate"
          type="number"
          className="mb-5"
        />

        <LabelInput
          formik={formik}
          name="return_of_investment"
          label="Return of Investment"
          type="number"
          className="mb-5"
        />

        <LabelInput
          formik={formik}
          name="total_bundles"
          label="Total Bundles"
          type="number"
          className="mb-5"
        />

        <LabelInput
          formik={formik}
          name="neighborhood"
          label="Neighborhood"
          className="mb-5"
          hint="Separate items by comma"
        />
        <LabelInput
          formik={formik}
          name="estateFeatures"
          label="Estate Features"
          className="mb-10"
          hint="Separate items by comma"
        />

        <Button type="submit">Update Invest</Button>
      </form>
    </>
  );
}

export default EditInvestForm;
