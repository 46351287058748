// import { GrowType } from '../../types';
import { GrowType } from "../../../types";
import { formatNumberToNaira } from "../../../utils/stringManipulations";

function GrowViewProperty({
  selectedGrowProperty,
}: {
  selectedGrowProperty: GrowType | { [key: string]: any };
}) {
  return (
    <div>
      <img
        src={selectedGrowProperty.images[0]}
        alt="Grow"
        className="rounded-md h-44 w-full object-cover"
      />
      <p className="mt-5  text-2xl">
        {selectedGrowProperty.title} - {selectedGrowProperty.property_name}
      </p>
      <p className=" mb-2 text-xs text-gray-500 capitalize">
        {selectedGrowProperty.address}, {selectedGrowProperty.city},{" "}
        {selectedGrowProperty.state}, {selectedGrowProperty.country}
      </p>
      <p className="mb-10 text-xs">{selectedGrowProperty.description}</p>
      <p className="mb-5 text-md capitalize">
        Mortgage Type: {selectedGrowProperty.mortgage_type}
      </p>
      <p className="mb-5 text-md capitalize">
        Format: {selectedGrowProperty.format}
      </p>
      <p className="mb-5 text-md capitalize">
        Interest: {selectedGrowProperty.interest}%
      </p>
      <p className="mb-5 text-md capitalize">
        Equity: {selectedGrowProperty.equity}
      </p>
      <p className="mb-5 text-md capitalize">
        Repayment Duration: {selectedGrowProperty.repayment_duration}
      </p>

      <p className="mb-5 text-md">Size: {selectedGrowProperty.size}</p>
      <p className="mb-5 text-md">
        Current price: {formatNumberToNaira(selectedGrowProperty.current_price)}
      </p>
      <p className="mb-5 text-md">
        Previous price:{" "}
        {formatNumberToNaira(selectedGrowProperty.previous_price)}
      </p>
      <p className="mb-5 text-md">
        Discount: {formatNumberToNaira(selectedGrowProperty.discount)}
      </p>

      <p className="mb-10 text-md">
        Features: <br /> {selectedGrowProperty.features?.join(", ")}
      </p>

      <p className="mb-2 text-sm">Images</p>
      <div className="grid grid-cols-2 gap-5">
        {selectedGrowProperty.images?.map((image: string) => (
          <img
            src={image}
            alt=""
            className="rounded-md h-32 w-full object-cover"
            key={image}
          />
        ))}
      </div>
    </div>
  );
}

export default GrowViewProperty;
