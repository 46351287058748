import React from "react";
import Card from "../../../common/Card/Card";
import { InvestmentObjType } from "../../../types";

const InvestmentDetails = ({
  detail,
}: {
  detail: InvestmentObjType | { [key: string]: any };
}) => {
  console.log(detail);
  return (
    <Card className="min-w-full w-full">
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="border-b-2 md:border-b-0 md:border-r-2 border-lightGrey md:pr-5 mb-5 md:mb-0 pb-5 md:pb-0">
          <p className="mb-5 text-black font-medium text-lg mt-2 md:mt-0">
            Details
          </p>
          <div className="flex flex-row items-center flex-wrap justify-between mb-2 text-black text-sm">
            <b>Property Name</b>
            <span className="capitalize">
              {detail.investedOn.property_name}
            </span>
          </div>
          <div className="flex flex-row items-center flex-wrap justify-between mb-2 text-black text-sm">
            <b>Repayment Duration</b>
            <span>{detail.repayment_duration}</span>
          </div>
          <div className="flex flex-row items-center flex-wrap justify-between mb-2 text-black text-sm">
            <b>Repayment Interest Rate</b>
            <span>{detail.repayment_interest_rate}</span>
          </div>

          <div className="flex flex-row items-center flex-wrap justify-between mb-2 text-black text-sm">
            <b>Down Payment</b>
            <span>{detail.down_payment}</span>
          </div>
          <div className="flex flex-row items-center flex-wrap justify-between mb-2 text-black text-sm">
            <b>Down Payment Rate</b>
            <span>{detail.down_payment_rate}</span>
          </div>
          <div className="flex flex-row items-center flex-wrap justify-between mb-2 text-black text-sm">
            <b>Date</b>
            <span>{new Date(detail.date_of_investment).toDateString()}</span>
          </div>
        </div>
        <div className="md:pl-5">
          <p className="mb-5 text-black font-medium text-lg mt-2 md:mt-0">
            {detail.product_type}
          </p>
          <img
            src={detail.investedOn.images[0]}
            alt=""
            className="h-32 w-full object-cover rounded-md mb-2"
          />
          <p className="mb-2 text-black text-sm text-md">
            {detail.investedOn.title} - {detail.investedOn.property_name}
          </p>
          <p className="text-gray-400 text-xs">
            {detail.investedOn.address}, {detail.investedOn.state}
          </p>
        </div>
      </div>
    </Card>
  );
};

export default InvestmentDetails;
