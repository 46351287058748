// import { GrowType } from '../../types';
import { GrowType } from "../../../types";
import { formatNumberToNaira } from "../../../utils/stringManipulations";

function ViewInvestProperty({
  selectedInvestProperty,
}: {
  selectedInvestProperty: GrowType | { [key: string]: any };
}) {
  return (
    <div>
      <img
        src={selectedInvestProperty.images[0]}
        alt="Grow"
        className="rounded-md h-44 w-full object-cover"
      />
      <p className="mt-5  text-2xl">
        {selectedInvestProperty.title} - {selectedInvestProperty.property_name}
      </p>
      <p className=" mb-2 text-xs text-gray-500 capitalize">
        {selectedInvestProperty.address}, {selectedInvestProperty.city},{" "}
        {selectedInvestProperty.state}, {selectedInvestProperty.country}
      </p>
      <p className="mb-10 text-xs">{selectedInvestProperty.description}</p>
      <p className="mb-5 text-md capitalize">
        Mortgage Type: {selectedInvestProperty.mortgage_type}
      </p>
      <p className="mb-5 text-md capitalize">
        Format: {selectedInvestProperty.format}
      </p>
      <p className="mb-5 text-md capitalize">
        Interest: {selectedInvestProperty.interest}%
      </p>
      <p className="mb-5 text-md capitalize">
        Equity: {selectedInvestProperty.equity}
      </p>
      <p className="mb-5 text-md capitalize">
        Repayment Duration: {selectedInvestProperty.repayment_duration}
      </p>

      <p className="mb-5 text-md">Size: {selectedInvestProperty.size}</p>
      <p className="mb-5 text-md">
        Current price:{" "}
        {formatNumberToNaira(selectedInvestProperty.current_price)}
      </p>
      <p className="mb-5 text-md">
        Previous price:{" "}
        {formatNumberToNaira(selectedInvestProperty.previous_price)}
      </p>
      <p className="mb-5 text-md">
        Discount: {formatNumberToNaira(selectedInvestProperty.discount)}
      </p>

      <p className="mb-10 text-md">
        Features: <br /> {selectedInvestProperty.features?.join(", ")}
      </p>

      <p className="mb-2 text-sm">Images</p>
      <div className="grid grid-cols-2 gap-5">
        {selectedInvestProperty.images?.map((image: string) => (
          <img
            src={image}
            alt=""
            className="rounded-md h-32 w-full object-cover"
            key={image}
          />
        ))}
      </div>
    </div>
  );
}

export default ViewInvestProperty;
