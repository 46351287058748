import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { appAxios } from "../../api/axios";
import Button from "../../common/Button/Button";
import CustomModal from "../../common/CustomModal/CustomModal";
import DeleteForm from "../../components/Grow/DeleteForm";
import EditForm from "../../components/Grow/EditForm";
import ViewGrow from "../../components/Grow/ViewGrow";
import { sendCatchFeedback } from "../../utils/feedback";
import AppLayout from "../../layout/AppLayout";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  closeLoadingIndicator,
  openLoadingIndicator,
} from "../../store/slices/loadingIndicator";
import { GrowType } from "../../types";
import Pagination from "../../common/Pagination";
import { getUserSession } from "../../utils/userSession";
import GrowList from "../../components/Grow/GrowList";

function Grow() {
  const [grow, setGrow] = useState<GrowType[]>([]);
  const dispatch = useAppDispatch();
  const [selectedGrow, setSelectedGrow] = useState<
    GrowType | { [key: string]: any }
  >({});
  const [page, setPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const { limit } = useAppSelector((state) => state.pageLimit);

  const getGrow = async () => {
    const currentUser = getUserSession();

    dispatch(openLoadingIndicator({ text: "Fetching Grow Products" }));
    try {
      const response = await appAxios.get(`/grows?page=${page}&take=${limit}`, {
        headers: {
          Authorization: currentUser
            ? "Bearer " + currentUser?.token?.token
            : null,
        },
      });
      setGrow(response.data?.data?.grows?.data);
      setTotalResults(response.data?.data?.grows?.meta?.itemCount);
    } catch (error) {
      sendCatchFeedback(error);
    }
    dispatch(closeLoadingIndicator());
  };

  useEffect(() => {
    getGrow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page, limit]);

  const nextPage = async () => {
    setPage(page + 1);
  };
  const previousPage = async () => {
    setPage(page - 1);
  };

  // delete modal
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const openDeleteModal = (develop: GrowType) => {
    setSelectedGrow(develop);
    setDeleteModalOpen(true);
  };
  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  // view modal
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const openViewModal = (develop: GrowType) => {
    setSelectedGrow(develop);
    setViewModalOpen(true);
  };
  const closeViewModal = () => {
    setViewModalOpen(false);
  };

  // edit modal
  const [editModalOpen, setEditModalOpen] = useState(false);
  const openEditModal = (develop: GrowType) => {
    setSelectedGrow(develop);
    setEditModalOpen(true);
  };
  const closeEditModal = () => {
    setEditModalOpen(false);
  };

  return (
    <AppLayout
      pageAction={
        <div className="flex flex-row items-center flex-wrap gap-5">
          <Link to="/grow/requests">
            <Button className="h-10 bg-dark">View Requests</Button>
          </Link>
          <Link to="/grow/add">
            <Button className="h-10">Add Grow</Button>
          </Link>
        </div>
      }
    >
      <GrowList
        grow={grow}
        openDeleteModal={openDeleteModal}
        openViewModal={openViewModal}
        openEditModal={openEditModal}
      />
      {grow.length > 0 && (
        <Pagination
          page={page}
          totalResults={totalResults}
          nextPageChange={nextPage}
          previousPageChange={previousPage}
        />
      )}

      {/* Edit Modal */}
      <CustomModal
        modalState={editModalOpen}
        closeModal={closeEditModal}
        title="Edit Grow"
      >
        <EditForm
          selectedGrow={selectedGrow}
          closeEditModal={closeEditModal}
          setGrow={setGrow}
          grow={grow}
        />
      </CustomModal>

      {/* Delete Modal */}
      <CustomModal
        modalState={deleteModalOpen}
        closeModal={closeDeleteModal}
        title="Delete Grow"
      >
        <DeleteForm
          selectedGrow={selectedGrow}
          closeDeleteModal={closeDeleteModal}
          setGrow={setGrow}
          grow={grow}
        />
      </CustomModal>

      {/* View Modal */}
      <CustomModal
        modalState={viewModalOpen}
        closeModal={closeViewModal}
        title="Grow Details"
      >
        <ViewGrow selectedGrow={selectedGrow} />
      </CustomModal>
    </AppLayout>
  );
}

export default Grow;
