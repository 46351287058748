import Button from "../../common/Button/Button";
import Card from "../../common/Card/Card";
import { InvestType } from "../../types";

function InvestCard({
  invest,
  openDeleteModal,
  openViewModal,
  openEditModal,
}: {
  invest: InvestType;
  openDeleteModal: (d: InvestType) => void;
  openViewModal: (d: InvestType) => void;
  openEditModal: (d: InvestType) => void;
}) {
  return (
    <Card
      className={"w-full min-w-full shadow-md p-5 rounded-lg dark:bg-lightDark"}
    >
      <div className="flex flex-col md:flex-row justify-between items-stretch gap-5">
        <div className="flex flex-col md:flex-row gap-5">
          <img
            src={invest.images[0]}
            alt="Develop"
            className="w-full md:w-36 h-36 rounded-md object-cover"
          />
          <div className="flex flex-col gap-2">
            <span className="font-medium text-xl text-black">
              {invest.title}{" "}
            </span>
            <span className="line-clamp text-black text-sm">
              {invest.description}{" "}
            </span>
            <span className="line-clamp text-sm  text-gray-400">
              {invest.address}{" "}
            </span>
            <span className="text-sm">{invest.property_id} </span>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <Button
            className="!h-4 w-full md:w-32 bg-dark"
            onClick={() => openViewModal(invest)}
          >
            View
          </Button>
          <Button
            className="!h-4 w-full md:w-32 bg-dark"
            onClick={() => openEditModal(invest)}
          >
            Edit
          </Button>

          <Button
            className="!h-4 w-full md:w-32 bg-error"
            onClick={() => openDeleteModal(invest)}
          >
            Delete
          </Button>
        </div>
      </div>
    </Card>
  );
}

export default InvestCard;
