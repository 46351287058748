import React, { useState } from "react";
import {
  Users,
  GrowGenType,
  InvestGenType,
  developGeneralType,
  developInvestmentType,
  previewGrowType,
} from "../../types";
import userPerson from "../../assets/images/userPerson.png";
import Button from "../../common/Button/Button";
import AppLayout from "../../layout/AppLayout";
import { getUserSession } from "../../utils/userSession";
import { appAxios } from "../../api/axios";
import { sendCatchFeedback, sendFeedback } from "../../utils/feedback";
import CustomModal from "../../common/CustomModal/CustomModal";
import PaymentPlan from "../../components/LogInvestment/PaymentPlan";
import Select from 'react-select';

const LogInvestment = () => {
  const [userId, setUserId] = useState("");
  // const [productEng  , setProductEng] = useState(false)
  const [quantity, setQuantity] = useState<string>("");
  const [productId, setProductId] = useState("");
  const [productName, setProductName] = useState("");
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [previewGrow, setPreviewGrow] = useState<
    previewGrowType | { [key: string]: any }
  >({});
  const [selectedOption, setSelectedOption] = useState(null);
  const [products, setProducts] = useState<[]>();
  // const [growResults , setGrowResults] = useState<GrowGenType>()
  // const [developResults , setDevelopResults] = useState<developGeneralType>()
  // const [investResults , setInvestResults] = useState<InvestGenType>()
  const [growPropertyType, setGrowPropertyType] = useState(false);
  const [investPropertyType, setInvestPropertyType] = useState(false);
  const [developPropertyType, setDevelopPropertyType] = useState(false);
  const [repaymentCycle, setRepaymentCycle] = useState("");
  const [repaymentDuration, setRepaymentDuration] = useState("");
  const [developRes, setDevelopRes] = useState<developInvestmentType>();
  const [searchTerm, setSearchTerm] = useState("");
  const [all, setAll] = useState<Users>();
  const [showForm, setShowForm] = useState<boolean>(true);
  const [genUsers, setGenUser] = useState<string>("");
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const [investEnabled, setInvestEnabled] = useState<boolean>();

  // const listChange = (id : string ) => {
  // 	console.log(id)
  // 	setProductId(id)
  // 	setProductEng(false)

  // }

  const closeViewModal = () => {
    setViewModalOpen(false);
  };

  const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' },
  ];
  

  const getGrows = async () => {
    const currentUser = getUserSession();

    try {
      const response = await appAxios.get(
        `/grows`,
        {
          headers: {
            Authorization: currentUser
              ? "Bearer " + currentUser?.token?.token
              : null,
          },
        }
      );

      // if(response.data.data.grows.data.length > 0){
      //     setGrowResults(response)

      // }
      // if(id.length === 1){
      // 	setProductEng(false)
      // }
      // if(id.length > 1){
      // 	setProductEng(true)
      // }

      // if(response.data.data.grows.data.length > 1){
      //     sendFeedback("Grow product found")
      // }

      setProducts(response?.data?.data?.grows?.data);
    } catch (error) {
      console.log(error);
    }
  };
  const onChange = (option: any) => {
    setSelectedOption(option._id)
  }

  const getDevelops = async () => {
    const currentUser = getUserSession();

    // console.log(currentUser)
    try {
      const response = await appAxios.get(
        `/develops`,
        {
          headers: {
            Authorization: currentUser
              ? "Bearer " + currentUser?.token?.token
              : null,
          },
        }
      );

      // if(response.data.data.develops.data.length > 0){
      //     setDevelopResults(response)

      // }
      // if(id.length === 1){
      // 	setProductEng(false)
      // }
      // if(id.length > 1){
      // 	setProductEng(true)
      // }

      //    if(response?.data?.data?.develops?.data.length > 1){
      // 	  sendFeedback("Develop product found")
      //    }
      setProducts(response?.data?.data?.develops?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getInvests = async () => {
    const currentUser = getUserSession();

    // console.log(currentUser)
    try {
      const response = await appAxios.get(
        `/invests`,
        {
          headers: {
            Authorization: currentUser
              ? "Bearer " + currentUser?.token?.token
              : null,
          },
        }
      );

      // if(response?.data?.data?.invests?.data?.length > 1){
      // 	sendFeedback("Invest product found")
      // }
      setProducts(response?.data?.data?.invests?.data);
    } catch (error) {
      sendCatchFeedback(error);
    }
  };

  

  

  

  // useEffect(() => {
  // 	const getProductSearch = setTimeout(() => {

  //        getDevelops(searchTerm)

  // 	})

  // 	return () => clearTimeout(getProductSearch)

  // }, [searchTerm])

  const getTypes = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    console.log(e.currentTarget.value);

    if (e.currentTarget.value === "Select a Property Type") {
      setGrowPropertyType(false);
      setDevelopPropertyType(false);
      setInvestPropertyType(false);
      setInvestEnabled(false);
    }

    if (e.currentTarget.value === "Grow") {
      setGrowPropertyType(true);
      setDevelopPropertyType(false);
      setInvestPropertyType(false);
      setInvestEnabled(false);
      getGrows();
    }

    if (e.currentTarget.value === "Develop") {
      setGrowPropertyType(false);
      setDevelopPropertyType(true);
      setInvestPropertyType(false);
      setInvestEnabled(false);
      getDevelops()

    }

    if (e.currentTarget.value === "Invest") {
      setGrowPropertyType(false);
      setDevelopPropertyType(false);
      setInvestPropertyType(true);
      setInvestEnabled(true);
      getInvests()

    }
  };

  const growType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setRepaymentCycle(e.currentTarget.value);
  };

  const handleChange = (id: string) => {
    console.log(id);
    setUserId(id);
    setShowSearch(false);
    setShowForm(false);
  };

  

  const growPreview = async () => {
    console.log("I am here grow preview");

    const currentUser = getUserSession();

    console.log(currentUser);

    const body = {
      user_id: userId,
      product_id: selectedOption,
      repayment_cycle: repaymentCycle,
      repayment_duration: parseInt(repaymentDuration),
    };

    try {
      const response: previewGrowType = await appAxios.post(
        `/investments/preview/grow/plan`,
        body,
        {
          headers: {
            Authorization: currentUser
              ? "Bearer " + currentUser?.token?.token
              : null,
          },
        }
      );
      console.log(response);
      setPreviewGrow(response);
    } catch (error) {
      sendCatchFeedback(error);
    }
  };

  const developPreview = async () => {
    console.log("I am here grow preview");

    const currentUser = getUserSession();

    console.log(currentUser);

    const body = {
      user_id: userId,
      product_id: selectedOption,
      repayment_cycle: repaymentCycle,
      repayment_duration: parseInt(repaymentDuration),
    };

    try {
      const response: previewGrowType = await appAxios.post(
        `/investments/preview/develop/plan`,
        body,
        {
          headers: {
            Authorization: currentUser
              ? "Bearer " + currentUser?.token?.token
              : null,
          },
        }
      );
      setPreviewGrow(response);
    } catch (error) {
      sendCatchFeedback(error);
    }
  };

  const growInvestment = async () => {
    const currentUser = getUserSession();

    console.log(currentUser);

    const body = {
      user_id: userId,
      product_id: selectedOption,
      repayment_cycle: repaymentCycle,
      repayment_duration: parseInt(repaymentDuration),
    };

    try {
      const response: developInvestmentType = await appAxios.post(
        `/investments/grow`,
        body,
        {
          headers: {
            Authorization: currentUser
              ? "Bearer " + currentUser?.token?.token
              : null,
          },
        }
      );
      console.log(response);
      setDevelopRes(response);

      sendFeedback("Grow logged successfully ...");

      setRepaymentCycle("");
      setRepaymentDuration("");
      setProductName("");
    } catch (error) {
      sendCatchFeedback(error);
    }
  };

  const developInvestment = async () => {
    const currentUser = getUserSession();

    console.log(currentUser);

    const body = {
      user_id: userId,
      product_id: selectedOption,
      repayment_cycle: repaymentCycle,
      repayment_duration: parseInt(repaymentDuration),
    };

    // dispatch(openLoadingIndicator({ text: 'Creating a develop Investment ...' }));
    try {
      const response: developInvestmentType = await appAxios.post(
        `/investments/develop`,
        body,
        {
          headers: {
            Authorization: currentUser
              ? "Bearer " + currentUser?.token?.token
              : null,
          },
        }
      );
      console.log(response);

      setDevelopRes(response);

      sendFeedback("Develop logged successfully ...");
      setRepaymentCycle("");
      setRepaymentDuration("");
      setProductName("");
    } catch (error) {
      console.log(error);
    }
    // dispatch(closeLoadingIndicator());
  };

  const investInvestment = async () => {
    const currentUser = getUserSession();

    console.log(currentUser);

    const body = {
      user_id: userId,
      product_id: selectedOption,
      quantity: parseInt(quantity),
      duration: parseInt(repaymentDuration),
    };
    try {
      const response: developInvestmentType = await appAxios.post(
        `/investments/invest`,
        body,
        {
          headers: {
            Authorization: currentUser
              ? "Bearer " + currentUser?.token?.token
              : null,
          },
        }
      );
      console.log(response);
      setDevelopRes(response);

      sendFeedback("Invest logged successfully ...");
      setQuantity("");
      setRepaymentDuration("");
      setProductName("");
    } catch (error) {
      sendCatchFeedback(error);
    }
  };

  const generalSubmit = async () => {
    if (growPropertyType && selectedOption) {

      growPreview();
      await setViewModalOpen(true);
    } else if (developPropertyType && selectedOption) {
      developPreview();
      await setViewModalOpen(true);
      //    developInvestment()
    } else if (investPropertyType && selectedOption) {
      investInvestment();
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGenUser(e.currentTarget.value);
    getAllUsers();
  };

  const getAllUsers = async () => {
    if (genUsers.length > 0) {
      const currentUser = getUserSession();

      try {
        const response: Users = await appAxios.get(
          `/users/search?searchTerm=${genUsers}&user_type=user`,
          {
            headers: {
              Authorization: currentUser
                ? "Bearer " + currentUser?.token?.token
                : null,
            },
          }
        );
        console.log(response);

        if (response.data.data.users.data.length > 0) {
          setAll(response);
          setShowSearch(true);
        }

        if (genUsers.length === 1) {
          setShowSearch(false);
          setShowForm(true);
          setAll(undefined);
          setUserId("");
        }
      } catch (error) {
        sendCatchFeedback(error);
      }
    }
  };
  return (
    <AppLayout>
      <div>
        <div
          className="
		xl:flex  xl:gap-x-3 xl:mt-5 xl:ml-5
		lg:flex  lg:gap-x-3 lg:mt-5 lg:ml-5
		sm:flex  sm:gap-x-3 sm:mt-5 sm:ml-5
		xs:flex  xs:gap-x-3 xs:mt-5 xs:ml-[-0.7rem]
		
		"
        >
          <div className=" flex flex-col justify-start gap-y-3">
            <div className="w-[57.45px] h-[21px]">
              <h6 className="text-black text-md">User</h6>
            </div>

            <div
              className="
				xl:w-[71.8rem] xl:h-[50px] xl:bg-white xl:shadow-lg xl:rounded-md xl:flex
				lg:w-[45rem] lg:h-[50px] lg:bg-white lg:shadow-lg lg:rounded-md lg:flex 
				sm:w-[45rem] sm:h-[50px] sm:bg-white sm:shadow-lg sm:rounded-md sm:flex 
				xs:w-[20rem] xs:h-[50px] xs:bg-white xs:shadow-lg xs:rounded-md xs:flex 
				
				"
            >
              <input
                type="text"
                className="outline-none border border-none ml-8"
                onChange={(e) => handleSearch(e)}
                placeholder="Search User"
              />
            </div>
          </div>
          {/* <div className='flex items-end gap-x-3'>
				
					<div className='w-[122px] h-[51px] bg-white rounded-md flex items-center justify-center gap-x-2' onClick = {getFilters}>
                        <div>
                           <img src={filterImg} alt="" />
						</div>
						<div>
                           <h6 className='text-md'>Filter by</h6>
						</div>
					</div>
			        <div className='w-[122px] h-[50px] flex items-center rounded-md justify-center bg-primary gap-x-2' onClick = {getAllUsers}>
                        <div>
                           <img src={search} alt="" />
						</div>
						<div>
						   <h6 className='text-md text-white'>Search</h6>
						</div>
					</div>


              
			</div> */}
        </div>
        <div className="flex   justify-between  gap-x-2">
          <div>
            {all && showSearch
              ? all?.data?.data?.users?.data?.map((info) => (
                  <div
                    className="xl:w-[71.8rem] xl:h-[46px] xl:bg-white xl:shadow-md xl:rounded-md xl:flex xl:items-center
							xl:justify-start xl:gap-x-2 xl:ml-5 xl:mt-2 xl:hover:bg-[#F2F6FC] xl:cursor-pointer 
							lg:w-[30rem] lg:h-[46px] lg:bg-white lg:shadow-md lg:rounded-md lg:flex lg:items-center
							lg:justify-start lg:gap-x-2 lg:ml-5 lg:mt-2 lg:hover:bg-[#F2F6FC] lg:cursor-pointer
							sm:w-[45rem] sm:h-[46px] sm:bg-white sm:shadow-md sm:rounded-md sm:flex sm:items-center
							sm:justify-start sm:gap-x-2 sm:ml-0 sm:mt-2 sm:hover:bg-[#F2F6FC] sm:cursor-pointer
							xs:w-[18rem] xs:h-[46px] xs:bg-white xs:shadow-md xs:rounded-md xs:flex xs:items-center
							xs:justify-start xs:gap-x-2 xs:ml-[-0.7rem] xs:mt-2 xs:hover:bg-[#F2F6FC] xs:cursor-pointer
							"
                    onClick={() => handleChange(info?._id)}
                  >
                    <div className="ml-8">{info?.first_name}</div>
                    <div>-</div>
                    <div>{info.phone_number}</div>
                  </div>
                ))
              : ""}
          </div>
        </div>
        {/* <div className='absolute top-[5.2rem] right-5'>
			
		</div> */}

        {/* Profile */}

        {all?.data?.data?.users?.data
          ?.filter((x) => x._id === userId)
          ?.map((info, i) => (
            <div
              className={`${
                !showForm
                  ? "opacity-1 pointer-events-auto"
                  : "opacity-[0.4] pointer-events-none"
              } 
			xl:flex xl:flex-row xl:gap-x-3 
			lg:flex lg:flex-col  lg:gap-x-3
			sm:flex sm:flex-col sm:gap-y-3
			xs:flex xs:flex-col xs:gap-y-3
			`}
              key={info._id}
            >
              <div
                className="
			xl:bg-white xl:rounded-md xl:shadow-md xl:flex xl:gap-x-[3rem] xl:w-[71.8rem] xl:h-[244px] xl:mt-4 xl:ml-5 xl:p-8
			lg:bg-white lg:rounded-md lg:shadow-md lg:flex lg:gap-x-[3rem] lg:w-[45rem] lg:h-[244px] lg:mt-4 lg:ml-5 lg:p-8
			sm:bg-white sm:rounded-md sm:shadow-md sm:flex  sm:gap-x-[3rem] sm:w-[45rem] sm:h-[244px] sm:mt-4 sm:ml-0 sm:p-8
			xs:bg-white xs:rounded-md xs:shadow-md xs:flex  xs:gap-x-[0rem] xs:w-[20rem] xs:h-[244px] xs:mt-4 xs:ml-[-0.7rem] xs:p-8
			"
              >
                <div>
                  <img src={userPerson} alt="" className="" />
                </div>
                <div className="flex flex-col gap-y-4">
                  <div>
                    <div className="w-[90px] h-[18px]">
                      <h6 className="text-[#808191] text-sm">First Name</h6>
                    </div>
                  </div>
                  <div className="">
                    <div className="w-[90px] h-[18px]">
                      <h6 className="text-[#808191] text-sm">Last Name</h6>
                    </div>
                  </div>

                  <div className="">
                    <div className="w-[33x] h-[18px]">
                      <h6 className="text-[#808191] text-sm">Email</h6>
                    </div>
                  </div>
                  <div className="">
                    <div className="w-[120px] h-[18px]">
                      <h6 className="text-[#808191] text-sm">Phone Number</h6>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col gap-y-3.5">
                  <div>
                    <div className="">
                      <h6 className="text-[#808191] text-sm">
                        {info?.first_name}
                      </h6>
                    </div>
                  </div>
                  <div className="">
                    <div className="">
                      <h6 className="text-[#808191] text-sm">
                        {info?.last_name}
                      </h6>
                    </div>
                  </div>

                  <div className="">
                    <div className="">
                      <h6 className="text-[#808191] text-sm">{info?.email}</h6>
                    </div>
                  </div>
                  <div className="">
                    <div className="">
                      <h6 className="text-[#808191] text-sm">
                        {info?.phone_number}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}

        {/* Form */}
        {
          <div
            className={`${
              !showForm
                ? "opacity-1 pointer-events-auto"
                : "opacity-[0.4] pointer-events-none"
            }
		xl:w-[71.9rem] xl:h-[330px] xl:bg-white xl:shadow-md xl:rounded-md xl:p-3 xl:flex xl:flex-col xl:gap-y-6 xl:ml-5 xl:mt-4
		lg:w-[45.0rem] lg:h-[25rem] lg:bg-white lg:shadow-md lg:rounded-md lg:p-3 lg:flex lg:flex-col lg:gap-y-6 lg:ml-5 lg:mt-4
		sm:w-[45.0rem] sm:h-[25rem] sm:bg-white sm:shadow-md sm:rounded-md sm:p-3 sm:flex sm:flex-col sm:gap-y-6 sm:ml-0 sm:mt-4
		xs:w-[20.0rem] xs:h-[28.8rem] xs:bg-white xs:shadow-md xs:rounded-md xs:p-3 xs:flex xs:flex-col xs:gap-y-6 xs:ml-[-0.7rem] xs:mt-4
		`}
          >
            <div
              className="
			xl:flex xl:flex-row xl:justify-start xl:gap-x-2
			lg:flex lg:flex-col lg:justify-start lg:gap-y-3
			sm:flex sm:flex-col sm:justify-start sm:gap-y-3
			xs:flex xs:flex-col xs:justify-start xs:gap-y-3

			
			"
            >
              <div className="flex flex-col gap-y-2">
                <div className="w-[92px] h-[21px]">
                  <h6 className="text-sm text-[#C4C4C4]">Product Type</h6>
                </div>
                <div
                  className="
					xl:w-[439px] xl:h-[50px] xl:border xl:border-[#C4C4C4] xl:flex xl:items-center 
					lg:w-[550px] lg:h-[50px] lg:border lg:border-[#C4C4C4] lg:flex lg:items-center 
					sm:w-[550px] sm:h-[50px] sm:border sm:border-[#C4C4C4]sm:flex sm:items-center
					xs:w-[300px] xs:h-[50px] xs:border xs:border-[#C4C4C4] xs:flex xs:items-centxs
					
					"
                >
                  <select
                    className="outline-none border bg-transparent border-none w-[439px] h-[40px]"
                    onChange={(e) => getTypes(e)}
                  >
                    <option value="Select a Product Type">
                      Select a Product Type
                    </option>
                    <option value="Grow">Grow</option>
                    <option value="Invest">Invest</option>
                    <option value="Develop">Develop</option>
                  </select>
                </div>
              </div>
              {/* {`${selected === 'develop' ? 'bg-secondary text-white' : 'bg-white text-success'}} */}
              <div className="flex flex-col gap-y-2 relative">
                <div
                  className="
					w-[101px] h-[21px]
					"
                >
                  <h6 className="text-sm text-[#C4C4C4]">Product Name</h6>
                </div>
                <div
                  className="
					xl:w-[554px] xl:h-[50px] xl:border xl:border-[#C4C4C4] xl:opacity-1 xl:flex xl:items-center
					lg:w-[554px] lg:h-[50px] lg:border lg:border-[#C4C4C4] lg:opacity-1 lg:flex lg:items-center
					sm:w-[554px] sm:h-[50px] sm:border sm:border-[#C4C4C4] sm:opacity-1 sm:flex sm:items-center
					xs:w-[300px] xs:h-[50px] xs:border xs:border-[#C4C4C4] xs:opacity-1 xs:flex xs:items-center
					
					"
                >
                  {/* <input
                    type="text"
                    className="outline-none border border-none"
                    onChange={(e) => getProductInfo(e)}
                    value={productName}
                    disabled={
                      !growPropertyType &&
                      !investPropertyType &&
                      !developPropertyType
                    }
                  /> */}
                  <Select 
                  defaultValue={selectedOption}
                  // @ts-ignore
                  onChange={onChange}
                  options={products}
                  getOptionLabel={(option: any) => option.title}
                  getOptionValue={(option: any) => option._id}
                  />
                </div>
                {/* <div>
                       {growResults && productEng ? growResults?.data.data?.grows?.data?.map(x => (
                           
						   <div className='bg-white w-[554px] h-[50px] shadow-lg rounded-md absolute top-[5rem] right-0 z-10'>
						       <div className='bg-[#C4C4C4] text-black p-3 overflow-y-auto' onClick = {() => listChange(x._id)}>
                                   {x.title}
							   </div>
							   
						   </div>
					   ))
					
					   : ''}

                        {developResults && productEng ? developResults?.data.data?.develops?.data?.map(x => (
                           
						   <div className='bg-white w-[554px] h-[50px] shadow-lg rounded-md absolute top-[5rem] right-0 z-10'>
						       <div className='bg-[#C4C4C4] text-black p-3 overflow-y-auto' onClick = {() => listChange(x._id)}>
                                   {x.title}
							   </div>
							   
						   </div>
					   ))
					
					   : ''}
			     	</div> */}
              </div>
            </div>
            <div
              className="
			xl:flex xl:flex-row xl:justify-start xl:gap-x-2
			lg:flex lg:flex-row lg:justify-start lg:gap-x-2
			sm:flex sm:flex-row sm:justify-start sm:gap-x-2
			xs:flex xs:flex-col xs:justify-start xs:gap-y-2
			"
            >
              <div className="flex flex-col gap-y-2">
                <div className="w-[150.93px] h-[21px]">
                  <h6 className="text-sm text-[#C4C4C4]">Repayment Duration</h6>
                </div>
                <div
                  className="
					xl:w-[238.82px] xl:h-[50px] xl:border xl:border-[#C4C4C4] xl:flex xl:center
					lg:w-[260.82px] lg:h-[50px] lg:border lg:border-[#C4C4C4] lg:flex lg:center
					sm:w-[260.82px] sm:h-[50px] sm:border sm:border-[#C4C4C4] sm:flex lg:center
					xs:w-[300.82px] xs:h-[50px] xs:border xs:border-[#C4C4C4] xs:flex xs:center
					"
                >
                  <input
                    type="text"
                    className="outline-none border border-none bg-transparent"
                    onChange={(e) => setRepaymentDuration(e.target.value)}
                    value={repaymentDuration}
                  />
                </div>
              </div>
              {investEnabled ? (
                <div className="flex flex-col gap-y-2">
                  <div className="w-[150.93px] h-[21px]">
                    <h6 className="text-sm text-[#C4C4C4]">Quantity</h6>
                  </div>
                  <div
                    className="
					xl:w-[238.82px] xl:h-[50px] xl:border xl:border-[#C4C4C4] xl:flex xl:center
					lg:w-[260.82px] lg:h-[50px] lg:border lg:border-[#C4C4C4] lg:flex lg:center
					sm:w-[260.82px] sm:h-[50px] sm:border sm:border-[#C4C4C4] sm:flex lg:center
					xs:w-[300.82px] xs:h-[50px] xs:border xs:border-[#C4C4C4] xs:flex xs:center
					"
                  >
                    <input
                      type="text"
                      className="outline-none border border-none bg-transparent"
                      value={quantity}
                      onChange={(e) => setQuantity(e.target.value)}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
              {!investEnabled ? (
                <div className="flex flex-col gap-y-2">
                  <div className="w-[126.51px] h-[21px]">
                    <h6 className="text-sm text-[#C4C4C4]">Repayment Cycle</h6>
                  </div>
                  <div
                    className="
						xl:w-[240.61px] xl:h-[50px] xl:border xl:border-[#C4C4C4] xl:flex xl:items-center
						lg:w-[289.00px] lg:h-[50px] lg:border lg:border-[#C4C4C4] lg:flex lg:items-center
						sm:w-[289.00px] sm:h-[50px] sm:border sm:border-[#C4C4C4] sm:flex sm:items-center
						xs:w-[300.82px] xs:h-[50px] xs:border xs:border-[#C4C4C4] xs:flex xs:items-center
						"
                  >
                    <div
                      className="
						xl:w-[238.82px] xl:h-[50px] xl:border xl:border-[#C4C4C4] xl:flex xl:items-center
						lg:w-[289.00px] lg:h-[50px] lg:border lg:border-[#C4C4C4] lg:flex lg:items-center
						sm:w-[289.00px] sm:h-[50px] sm:border sm:border-[#C4C4C4] sm:flex sm:items-center
						xs:w-[300.82px] xs:h-[50px] xs:border xs:border-[#C4C4C4] xs:flex xs:items-center
						
						"
                    >
                      <select
                        className="outline-none bg-transparent  border border-none w-[439px] h-[40px]"
                        value={repaymentCycle}
                        onChange={(e) => growType(e)}
                      >
                        <option value="Select a Repayment Cycle">
                          Select a Repayment Cycle
                        </option>
                        <option value="yearly">Yearly</option>
                        <option value="quarterly">Quarterly</option>
                        <option value="monthly">Monthly</option>
                        <option value="weekly">Weekly</option>
                        <option value="daily">Daily</option>
                      </select>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="flex justify-start">
              <Button className="w-[122px] h-[50px]" onClick={generalSubmit}>
                Create
              </Button>
            </div>
          </div>
        }

        <CustomModal
          modalState={viewModalOpen}
          closeModal={closeViewModal}
          title="Payment Plan"
        >
          <PaymentPlan
            growInvestment={growInvestment}
            growPlan={previewGrow}
            developInvestment={developInvestment}
          />
        </CustomModal>
      </div>
    </AppLayout>
  );
};

export default LogInvestment;
