import { growRoutes } from './grow/index';
import { requestsRoutes } from './requests/index';
import { usersRoutes } from './users/index';
import { developsRoutes } from './develops/index';
import { authenticationRoutes } from './authentication';
import { dashboardRoutes } from './dashboard';
import { investRoutes } from './invest';
import { productRoutes } from './products';
import { logInvestmentRoutes } from './logInvestment/Index';
import { InvestmentRoutes } from './investments';
import { devProRoutes } from './devPro/Index';
import { EstateRoutes } from './estatePro/Index';
import { contactRoutes } from './contact';
import { thriftRoutes } from './thrift';
import { tribeRoutes } from './tribe';



export const routes = [
	...authenticationRoutes,
	...dashboardRoutes,
	...growRoutes,
	...developsRoutes,
	...investRoutes,
	...usersRoutes,
	...requestsRoutes,
	...productRoutes,
	...logInvestmentRoutes,
	...InvestmentRoutes,
	...devProRoutes,
	...EstateRoutes,
	...contactRoutes,
	...thriftRoutes,
	...tribeRoutes,

];
