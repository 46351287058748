import React from 'react';
import CSVImage from '../../../assets/icons/csv.svg';
import { CSVLink, CSVDownload } from "react-csv";

function CSVButton({ data }: any) {
    console.log(data);
    const headers = [
        {label: "Email", key: 'email'},
        {label: "Name", key: 'name'},
        {label: "Phone Number", key: 'phone_number'},
        {label: "Quantity", key: 'quantity'},
        {label: "Date", key: 'date'},
        {label: "Time", key: 'time'},
        {label: "Title", key: 'develop.title'},
        {label: "Property Name", key: 'develop.property_name'},
        {label: "Property Address", key: 'develop.address'},
        {label: "Current Price", key: 'develop.current_price'},
        {label: "Inspection", key: 'inspection'},

    ]
    return (


        <CSVLink data={data} filename={"develop-requests.csv"} headers={headers}>
            <button
                className='p-2 pl-5 pr-5 hover:bg-dark bg-lightDark rounded flex gap-2 text-white'
            >
                <img src={CSVImage} alt='' />CSV
            </button>

        </CSVLink>
    );
}

export default CSVButton;
