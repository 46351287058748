import AddDevelopForm from '../../components/Develops/AddDevelopForm';
import AppLayout from '../../layout/AppLayout';

function AddDevelop() {
	return (
		<AppLayout pageTitle='Add Develop'>
			<AddDevelopForm />
		</AppLayout>
	);
}

export default AddDevelop;
