import AddInvestForm from '../../components/Invest/AddInvestForm';
import AppLayout from '../../layout/AppLayout';

function AddInvest() {
	return (
		<AppLayout pageTitle='Add Invest'>
			<AddInvestForm />
		</AppLayout>
	);
}

export default AddInvest;
