import AppLayout from '../../layout/AppLayout'
import { ProductNav } from '../../components/Product/ProductNav'
import {useState} from 'react'
import { ProductInvest } from './Invests/ProductInvest'
import { ProductGrow } from './Grow/ProductGrow'
import { ProductDevelop } from './Develop/ProductDevelop'
import UsersProperty from '../../components/Product/UsersProperty'




export const Products = () => {

    const [grow , setGrow] = useState<boolean>(true)
	const [invest , setInvest] = useState<boolean>(false)
	const [develop , setDevelop] = useState<boolean>(false)
	const [title , setTitle] = useState<string>('Grow Property')

	
	return (
		<>
		  	<AppLayout>
                <ProductNav
					setGrow = {setGrow}
					setInvest = {setInvest}
					setDevelop = {setDevelop}
					setTitle = {setTitle}
					title = {title}

				
				/>
                {invest ? <ProductInvest /> : ''}
				{grow ? <ProductGrow/> : ''}
				{develop ? <ProductDevelop /> : ''}
			
			</AppLayout>
		</>
	)
}