import { GrowType } from "../../types";
import { formatNumberToNaira } from "../../utils/stringManipulations";

function ViewGrow({
  selectedGrow,
}: {
  selectedGrow: GrowType | { [key: string]: any };
}) {
  return (
    <div>
      <img
        src={selectedGrow.images[0]}
        alt="Grow"
        className="rounded-md h-44 w-full object-cover"
      />
      <p className="mt-5  text-2xl">
        {selectedGrow.title} - {selectedGrow.property_name}
      </p>
      <p className=" mb-2 text-xs text-gray-500 capitalize">
        {selectedGrow.address}, {selectedGrow.city}, {selectedGrow.state},{" "}
        {selectedGrow.country}
      </p>
      <p className="mb-10 text-xs">{selectedGrow.description}</p>
      <p className="mb-5 text-md capitalize">
        Mortgage Type: {selectedGrow.mortgage_type}
      </p>
      <p className="mb-5 text-md capitalize">Format: {selectedGrow.format}</p>
      <p className="mb-5 text-md capitalize">
        Interest: {selectedGrow.interest}%
      </p>
      <p className="mb-5 text-md capitalize">Equity: {selectedGrow.equity}</p>
      <p className="mb-5 text-md capitalize">
        Repayment Duration: {selectedGrow.repayment_duration}
      </p>

      <p className="mb-5 text-md">Size: {selectedGrow.size}</p>
      <p className="mb-5 text-md">
        Current price: {formatNumberToNaira(selectedGrow.current_price)}
      </p>
      <p className="mb-5 text-md">
        Previous price: {formatNumberToNaira(selectedGrow.previous_price)}
      </p>
      <p className="mb-5 text-md">
        Discount: {formatNumberToNaira(selectedGrow.discount)}
      </p>

      <p className="mb-10 text-md">
        Features: <br /> {selectedGrow.features?.join(", ")}
      </p>

      <p className="mb-2 text-sm">Images</p>
      <div className="grid grid-cols-2 gap-5">
        {selectedGrow.images?.map((image: string) => (
          <img
            src={image}
            alt=""
            className="rounded-md h-32 w-full object-cover"
            key={image}
          />
        ))}
      </div>
    </div>
  );
}

export default ViewGrow;
