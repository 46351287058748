const navLinks: { label: string; href: string }[] = [
	{
		label: 'Dashboard',
		href: '/dashboard',
	},
	{
		label: 'Develops',
		href: '/develops',
	},
	{
		label: 'Products',
		href: '/products'
	},
	{
		label: 'Log Investment',
		href: '/log-investment'
	},
	{
		label: 'Investments',
		href: '/investments'
	},
	{
		label: 'Grow',
		href: '/grow',
	},
	{
		label: 'Users',
		href: '/users',
	},
	{
		label: 'Invest',
		href: '/invest'
	},
	{
		label: 'DevPro',
		href: '/devpro'
	},
	{
		label: 'EstatePro',
		href: '/estatepro'
	},
	{
		label: 'Contact',
		href: '/contact'
	},
	{
		label: 'Join our Tribe',
		href: '/tribe'
	},
	{
		label: 'Thrift',
		href: '/thrift'
	},


	
];

export default navLinks;
