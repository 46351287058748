import { redirect } from "react-router";
// import { sendFeedback } from '../functions/feedback';
// import { store } from '../store/store';

export const checkPrivateRoute = async () => {
  // const appStore = store.getState();
  // const currentUser: any = appStore.user.user;
  const currentUser = await JSON.parse(localStorage.getItem("user") || "{}");

  if (!Object.keys(currentUser).length) {
    // sendFeedback('Login to continue');
    throw redirect("/login");
  }
  return currentUser;
};

export const checkProtectedRoute = async () => {
  // const appStore = store.getState();
  // const currentUser: any = appStore.user.user;
  const currentUser = await JSON.parse(localStorage.getItem("user") || "{}");

  if (Object.keys(currentUser).length) {
    // sendFeedback('You are already logged in');
    throw redirect("/dashboard");
  }
  return currentUser;
};
