import { appAxios } from "../../api/axios";
import Pagination from "../../common/Pagination";
import { sendCatchFeedback } from "../../utils/feedback";
import { getUserSession } from "../../utils/userSession";
import AppLayout from "../../layout/AppLayout";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  closeLoadingIndicator,
  openLoadingIndicator,
} from "../../store/slices/loadingIndicator";
import { DevelopRequestType, DevProRequestType } from "../../types";
import { useState, useEffect, useRef } from "react";
import PrintButton from "../Develops/Requests/PrintButton";
import { useReactToPrint } from "react-to-print";
import DevProList from "../../components/DevPro/DevProList";

const DevPro = () => {
  const [requests, setRequests] = useState<DevProRequestType[]>([]);
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const { limit } = useAppSelector((state) => state.pageLimit);

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    const getRequests = async () => {
      const currentUser = getUserSession();

      dispatch(openLoadingIndicator({ text: "Fetching Requests" }));
      try {
        const response = await appAxios.get(
          `/pro/dev/requests?page=${page}&take=${limit}`,
          {
            headers: {
              Authorization: currentUser
                ? "Bearer " + currentUser?.token?.token
                : null,
            },
          }
        );
        console.log(response?.data?.data?.devProRequests?.meta);
        setRequests(response?.data?.data?.devProRequests?.data);
        setTotalResults(response.data?.data?.devProRequests?.meta?.itemCount);
      } catch (error) {
        sendCatchFeedback(error);
      }
      dispatch(closeLoadingIndicator());
    };
    getRequests();
  }, [dispatch, page, limit]);

  const nextPage = async () => {
    setPage(page + 1);
  };
  const previousPage = async () => {
    setPage(page - 1);
  };

  return (
    <AppLayout
      pageAction={<PrintButton handlePrint={handlePrint} />}
      pageTitle="DevPro"
    >
      <div ref={componentRef}>
        <DevProList requests={requests} />
      </div>
      {requests.length > 0 && (
        <Pagination
          page={page}
          totalResults={totalResults}
          nextPageChange={nextPage}
          previousPageChange={previousPage}
        />
      )}
    </AppLayout>
  );
};

export default DevPro;
