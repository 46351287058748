import { Dispatch, SetStateAction, useCallback } from "react";
import AddIcon from "../../assets/icons/add-square.png";
import DeleteIcon from "../../assets/icons/delete.png";
import LabelInput from "../../common/LabelInput/LabelInput";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { v4 as uuidv4 } from "uuid";

interface UnitType {
  [id: string]: {
    id: string;
    title: string;
    current_price: number;
    previous_price: number | undefined;
  };
}

function HouseUnits({
  units,
  setUnits,
}: {
  units: UnitType;
  setUnits: Dispatch<SetStateAction<UnitType>>;
}) {
  const addHouseUnit = () => {
    const randomID = uuidv4();

    setUnits({
      ...units,
      [randomID]: {
        id: randomID,
        title: "",
        current_price: 0,
        previous_price: undefined,
      },
    });
  };

  const removeHouseUnit = useCallback(
    (id: string) => {
      // Used a new object variable and set state so the component rerenders
      const newObject = units;
      delete newObject[id];

      setUnits({
        ...newObject,
      });
    },
    [units, setUnits]
  );

  const changeInputValue = ({
    id,
    type,
    value,
  }: {
    id: string;
    type: string;
    value: string;
  }) => {
    setUnits({
      ...units,
      [id]: {
        ...units[id],
        [type]:
          value !== ""
            ? type === "current_price" || type === "previous_price"
              ? Number(value)
              : value
            : value,
      },
    });
  };

  return (
    <div className="mb-10 mt-10 pt-2 pb-2 border-t-2 border-t-lightGrey dark:border-t-lightDark border-b-2 border-b-lightGrey dark:border-b-lightDark">
      <div className="flex flex-row flex-wrap gap-5 items-center justify-between mb-2">
        <p className="font-bold text-xl text-black">House Units</p>
        <div className="flex flex-row flex-wrap gap-5 items-center">
          <button
            className="text-blue-700 dark:text-blue-400 underline cursor-pointer"
            onClick={addHouseUnit}
            type="button"
          >
            <img src={AddIcon} alt="Add" className="dark:invert" />
          </button>
        </div>
      </div>
      {Object.keys(units)?.length ? (
        Object.values(units)?.map((unit, index) => (
          <div key={unit.id}>
            <div className="flex items-center mt-6 justify-center gap-5">
              <span className="text-black font-bold text-xl">
                Unit {index + 1}
              </span>
              <button
                className="text-blue-700 dark:text-blue-400 underline cursor-pointer"
                onClick={() => removeHouseUnit(unit.id)}
                type="button"
              >
                <img src={DeleteIcon} alt="Delete" className="dark:invert" />
              </button>
            </div>
            <LabelInput
              useFormik={false}
              name={"title" + index}
              label="Unit Title"
              className="mb-5"
              placeholder="Title of unit"
              value={units[unit.id]?.title}
              onChange={(e) =>
                changeInputValue({
                  id: unit.id,
                  type: "title",
                  value: e.target.value,
                })
              }
              showError={unit.title === ""}
              error="Required"
            />
            <LabelInput
              useFormik={false}
              name={"current_price" + index}
              label="Current Price"
              className="mb-5"
              placeholder="Current price of unit"
              value={units[unit.id]?.current_price}
              type="number"
              onChange={(e) =>
                changeInputValue({
                  id: unit.id,
                  type: "current_price",
                  value: e.target.value,
                })
              }
              showError={unit.current_price.toString() === ""}
              error="Required"
            />
            <LabelInput
              useFormik={false}
              name={"previous_price" + index}
              label="Previous Price"
              className="mb-5"
              placeholder="Previous price of unit"
              value={units[unit.id]?.previous_price}
              type="number"
              onChange={(e) =>
                changeInputValue({
                  id: unit.id,
                  type: "previous_price",
                  value: e.target.value,
                })
              }
            />
          </div>
        ))
      ) : (
        <span className="text-black">
          No unit added. Units are required for houses
        </span>
      )}
    </div>
  );
}

export default HouseUnits;
