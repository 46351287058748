import Grow from '../../pages/Grow';
import AddGrow from '../../pages/Grow/add';
import { checkPrivateRoute } from '../utils';

export const growRoutes = [
	{
		path: '/grow',
		element: <Grow />,
		loader: checkPrivateRoute,
	},
	{
		path: '/grow/add',
		element: <AddGrow />,
		loader: checkPrivateRoute,
	},
];
