import React from "react";
import { DevProRequestType } from "../../types";
import DevProCard from "./DevProCard";

const DevProList = ({ requests }: { requests: DevProRequestType[] }) => {
  console.log(requests);
  return (
    <div>
      <div className="flex flex-col gap-10">
        {requests.length > 0 ? (
          requests.map((request) => (
            <DevProCard request={request} key={request._id} />
          ))
        ) : (
          <span className="text-black">No request found</span>
        )}
      </div>
    </div>
  );
};

export default DevProList;
