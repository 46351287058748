import LogInvestment from '../../pages/LogInvestment/Index';
import { checkPrivateRoute } from '../utils';

export const logInvestmentRoutes = [
	{
		path: '/log-investment',
		element: <LogInvestment />,
		loader: checkPrivateRoute,
	},

];
