import { useEffect, useRef, useState } from "react";
import { appAxios } from "../../../api/axios";
import Pagination from "../../../common/Pagination";

import RequestsList from "../../../components/Develops/Requests/RequestsList";

import { sendCatchFeedback } from "../../../utils/feedback";
import { getUserSession } from "../../../utils/userSession";
import AppLayout from "../../../layout/AppLayout";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  closeLoadingIndicator,
  openLoadingIndicator,
} from "../../../store/slices/loadingIndicator";
import { DevelopRequestType } from "../../../types";
import { useReactToPrint } from "react-to-print";
import PrintButton from "./PrintButton";
import CSVButton from "./CSVButton";

function Requests() {
  const [requests, setRequests] = useState<DevelopRequestType[]>([]);
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const { limit } = useAppSelector((state) => state.pageLimit);

  useEffect(() => {
    const getRequests = async () => {
      const currentUser = getUserSession();

      dispatch(openLoadingIndicator({ text: "Fetching Requests" }));
      try {
        const response = await appAxios.get(
          `/develop/requests?page=${page}&take=${limit}`,
          {
            headers: {
              Authorization: currentUser
                ? "Bearer " + currentUser?.token?.token
                : null,
            },
          }
        );
        console.log(response);
        setRequests(response.data?.data?.developRequests?.data);
        setTotalResults(response.data?.data?.developRequests?.meta?.itemCount);
      } catch (error) {
        sendCatchFeedback(error);
      }
      dispatch(closeLoadingIndicator());
    };
    getRequests();
  }, [dispatch, page, limit]);

  const nextPage = async () => {
    setPage(page + 1);
  };
  const previousPage = async () => {
    setPage(page - 1);
  };

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  

  return (
    <AppLayout
      pageAction={(<div className="flex"><div className="mx-1"><PrintButton handlePrint={handlePrint} /></div><div><CSVButton data={requests} /></div> </div>)}
      pageTitle="Develop Requests"
    >
      <div ref={componentRef}>
        <RequestsList requests={requests} />
      </div>
      {requests.length > 0 && (
        <Pagination
          page={page}
          totalResults={totalResults}
          nextPageChange={nextPage}
          previousPageChange={previousPage}
        />
      )}
    </AppLayout>
  );
}

export default Requests;
