import React from "react";
import { GrowType } from "../../../types";
import InvestPropertyCard from "./InvestPropertyCard";

function InvestPropertyList({
  investProperty,
  openDeleteModal,
  openViewModal,
  openEditModal,
}: {
  investProperty: GrowType[];
  openDeleteModal: (d: GrowType) => void;
  openEditModal: (d: GrowType) => void;
  openViewModal: (d: GrowType) => void;
}) {
  if (!investProperty.length)
    return <span className="text-black">No grow product found</span>;
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 w-full gap-5 bg-white p-5 rounded mt-5">
      {investProperty.map((inProperty) => (
        <InvestPropertyCard
          investProperty={inProperty}
          key={inProperty._id}
          openDeleteModal={openDeleteModal}
          openViewModal={openViewModal}
          openEditModal={openEditModal}
        />
      ))}
    </div>
  );
}

export default InvestPropertyList;
