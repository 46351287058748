import Investments from '../../pages/Investments/Index';
import { checkPrivateRoute } from '../utils';

export const InvestmentRoutes = [
	{
		path: '/investments',
		element: <Investments />,
		loader: checkPrivateRoute,
	},

];