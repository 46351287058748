import Thrift from "../../pages/Thrift";
import { checkPrivateRoute } from "../utils";

export const thriftRoutes = [
  {
    path: "/thrift",
    element: <Thrift />,
    loader: checkPrivateRoute,
  },
];
