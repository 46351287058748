// import { GrowType } from '../../types';
import { GrowType } from "../../../types";
import { formatNumberToNaira } from "../../../utils/stringManipulations";

function DevelopViewProperty({
  selectedDevelopProperty,
}: {
  selectedDevelopProperty: GrowType | { [key: string]: any };
}) {
  return (
    <div>
      <img
        src={selectedDevelopProperty.images[0]}
        alt="Grow"
        className="rounded-md h-44 w-full object-cover"
      />
      <p className="mt-5  text-2xl">
        {selectedDevelopProperty.title} -{" "}
        {selectedDevelopProperty.property_name}
      </p>
      <p className=" mb-2 text-xs text-gray-500 capitalize">
        {selectedDevelopProperty.address}, {selectedDevelopProperty.city},{" "}
        {selectedDevelopProperty.state}, {selectedDevelopProperty.country}
      </p>
      <p className="mb-10 text-xs">{selectedDevelopProperty.description}</p>
      <p className="mb-5 text-md capitalize">
        Mortgage Type: {selectedDevelopProperty.mortgage_type}
      </p>
      <p className="mb-5 text-md capitalize">
        Format: {selectedDevelopProperty.format}
      </p>
      <p className="mb-5 text-md capitalize">
        Interest: {selectedDevelopProperty.interest}%
      </p>
      <p className="mb-5 text-md capitalize">
        Equity: {selectedDevelopProperty.equity}
      </p>
      <p className="mb-5 text-md capitalize">
        Repayment Duration: {selectedDevelopProperty.repayment_duration}
      </p>

      <p className="mb-5 text-md">Size: {selectedDevelopProperty.size}</p>
      <p className="mb-5 text-md">
        Current price:{" "}
        {formatNumberToNaira(selectedDevelopProperty.current_price)}
      </p>
      <p className="mb-5 text-md">
        Previous price:{" "}
        {formatNumberToNaira(selectedDevelopProperty.previous_price)}
      </p>
      <p className="mb-5 text-md">
        Discount: {formatNumberToNaira(selectedDevelopProperty.discount)}
      </p>

      <p className="mb-10 text-md">
        Features: <br /> {selectedDevelopProperty.features?.join(", ")}
      </p>

      <p className="mb-2 text-sm">Images</p>
      <div className="grid grid-cols-2 gap-5">
        {selectedDevelopProperty.images?.map((image: string) => (
          <img
            src={image}
            alt=""
            className="rounded-md h-32 w-full object-cover"
            key={image}
          />
        ))}
      </div>
    </div>
  );
}

export default DevelopViewProperty;
