import EstatePro from '../../pages/EstatePro/Index';
import { checkPrivateRoute } from '../utils';

export const EstateRoutes = [
	{
		path: '/estatepro',
		element: <EstatePro />,
		loader: checkPrivateRoute,
	},
	
];