import React from 'react';
import PrinterImage from '../../../assets/icons/printer.svg';

function PrintButton({ handlePrint }: { handlePrint: () => void }) {
	return (
		<button
			className='p-2 pl-5 pr-5 hover:bg-dark bg-lightDark rounded flex gap-2 text-white'
			onClick={handlePrint}
		>
			<img src={PrinterImage} alt='' />
			Print
		</button>
	);
}

export default PrintButton;
