import { InvestRequestType } from "../../../types";
import RequestInvestCard from "./RequestInvestCard";

function RequestsInvestList({ requests }: { requests: InvestRequestType[] }) {
  return (
    <div className="flex flex-col gap-10">
      {requests.length > 0 ? (
        requests.map((request: InvestRequestType) => (
          <RequestInvestCard request={request} />
        ))
      ) : (
        <span className="text-black">No request found</span>
      )}
    </div>
  );
}

export default RequestsInvestList;
