import React from "react";
import { tW } from "../../utils";

function Card({
  className,
  children,
  style,
}: {
  className?: string;
  style?: {};
  children: React.ReactNode;
}) {
  return (
    <div
      className={tW(
        "md:w-2/3 shadow-md p-7 rounded-lg max-w-screen-sm  bg-white ",
        className
      )}
    >
      {children}
    </div>
  );
}

export default Card;
