import RegisterForm from '../components/Register/RegisterForm';
import AppLayout from '../layout/AppLayout';

function Register() {
	return (
		<AppLayout showPageHeader={false}>
			<div className='min-h-main flex items-center justify-center dark:bg-mediumDark'>
				<RegisterForm />
			</div>
		</AppLayout>
	);
}

export default Register;
