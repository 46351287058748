import DevelopRequests from '../../pages/Develops/Requests';

import InvestRequests from '../../pages/Invest/Requests';

import GrowRequests from '../../pages/Grow/Requests';

import { checkPrivateRoute } from '../utils';

export const requestsRoutes = [
	{
		path: '/develops/requests',
		element: <DevelopRequests />,
		loader: checkPrivateRoute,
	},
	{

		path: '/invest/requests',
		element: <InvestRequests />,
		loader: checkPrivateRoute,
	},
	{
		path: '/grow/requests',
		element: <GrowRequests />,
		loader: checkPrivateRoute,
	},
];
