import AddVideoForm from '../../components/Tribe/AddVideoForm';
import AppLayout from '../../layout/AppLayout';

function AddVideo() {
	return (
		<AppLayout pageTitle='Add Coral Video'>
			<AddVideoForm />
		</AppLayout>
	);
}

export default AddVideo;
