import React from "react";
import { DevelopRequestType, EstateProType } from "../../types";
import EstateProCard from "./EstateProCard";

const EstateProList = ({ requests }: { requests: EstateProType[] }) => {
  return (
    <div>
      <div className="flex flex-col gap-10">
        {requests.length > 0 ? (
          requests.map((request) => (
            <EstateProCard request={request} key={request._id} />
          ))
        ) : (
          <span className="text-black">No request found</span>
        )}
      </div>
    </div>
  );
};

export default EstateProList;
