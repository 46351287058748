import { ProductNavTypes } from '../../types'
import Button from '../../common/Button/Button'
import React from 'react'



export const ProductNav = ({ setDevelop , setGrow, title , setInvest  ,setTitle}: ProductNavTypes) => {

	const [selected , setSelected] = React.useState('grow')

	const setToInvestPropertyPage = () => {
       setTitle('Invest Property')
       setInvest(true)
	   setDevelop(false)
	   setGrow(false)
       setSelected('invest')
	    

	}

	const setToDevelopPropertyPage = () => {
        setTitle('Develop Property')
        setDevelop(true)
		setInvest(false)
		setGrow(false)
	    setSelected('develop')
		
	} 

	const setToGrowPropertyPage = () => {
        setTitle('Grow Property')
		setGrow(true)
		setDevelop(false)
		setInvest(false)
		setSelected('grow')
	}





  return (
	<>
	<div
	   className='xs:flex xs:flex-col xs:gap-6  xs:w-full xs:h-[12rem] xs:p-4 sm:flex sm:flex-col sm:gap-6 
	   sm:w-full sm:h-[12rem] sm:p-4 lg:flex-row lg:items-center 
	   lg:justify-between lg:w-full lg:h-[3rem]'>
					<div className='flex'>
                        <h6 className='font-bold font-primary text-xl'>{title}</h6>
					</div>
					<div className='flex items-center justify-center space-x-4'>
						<Button onClick={setToGrowPropertyPage} className={`${selected === 'grow' ? 'bg-secondary text-white' : 'bg-white text-success'} shadow-xl
						 h-[40px] p-3 rounded-md w-full font-medium text-m 
						hover:bg-secondary hover:text-white`}
						>
						  Grow
						</Button> 
						<Button onClick={setToInvestPropertyPage} className={`${selected === 'invest' ? 'bg-secondary text-white' : 'bg-white text-success'} shadow-xl
						 h-[40px] p-3 rounded-md w-full font-medium text-m 
						hover:bg-secondary hover:text-white`}>
						 Invest
						</Button> 
						<Button onClick={setToDevelopPropertyPage} 
						className={`${selected === 'develop' ? 'bg-secondary text-white' : 'bg-white text-success'} shadow-xl
						h-[40px] p-3 rounded-md w-full font-medium text-m 
					   hover:bg-secondary hover:text-white`}
						>
						Develop
					    </Button> 
						
					</div>
					<div>
                         <Button className='bg-white shadow-xl border border-success text-success
						  h-[50px] p-3 rounded-md w-full font-medium text-m
						 hover:bg-primary hover:text-white'>
						Add Property
						</Button>
					</div>
                  
	</div>
	</>
  )
}
