import React from "react";
import { InvestType } from "../../types";
import InvestCard from "./InvestCard";

function InvestList({
  invests,
  openDeleteModal,
  openViewModal,
  openEditModal,
}: {
  invests: InvestType[];
  openDeleteModal: (d: InvestType) => void;
  openEditModal: (d: InvestType) => void;
  openViewModal: (d: InvestType) => void;
}) {
  console.log(invests);
  if (!invests?.length)
    return <span className="text-black">No Invest found</span>;
  return (
    <div className="flex flex-col w-full gap-5">
      {invests?.map((invest) => (
        <InvestCard
          invest={invest}
          key={invest._id}
          openDeleteModal={openDeleteModal}
          openViewModal={openViewModal}
          openEditModal={openEditModal}
        />
      ))}
    </div>
  );
}

export default InvestList;
