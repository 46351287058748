import Card from "../../common/Card/Card";
import { useFormik } from "formik";
import * as yup from "yup";
import LabelInput from "../../common/LabelInput/LabelInput";
import Button from "../../common/Button/Button";
import { appAxios } from "../../api/axios";
import { sendCatchFeedback, sendFeedback } from "../../utils/feedback";
import { useAppDispatch } from "../../store/hooks";
import {
  closeLoadingIndicator,
  openLoadingIndicator,
} from "../../store/slices/loadingIndicator";
import { useNavigate } from "react-router";
import { updateUser } from "../../store/slices/user";

function LoginForm() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: () => {
      submitValues();
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email("Enter a valid email")
        .required("Email is required"),
      password: yup.string().required("Password is required"),
    }),
  });

  const submitValues = async () => {
    dispatch(openLoadingIndicator({ text: "Signing in" }));
    try {
      const response = await appAxios.post("/auth/login", {
        email: formik.values.email,
        password: formik.values.password,
      });
      sendFeedback(response.data?.status, "success");
      const userObject = {
        ...response.data?.data?.user,
        token: response.data?.data?.jwt,
      };
      dispatch(updateUser({ user: userObject }));
      navigate("/dashboard");
    } catch (error) {
      sendCatchFeedback(error);
    }
    dispatch(closeLoadingIndicator());
  };

  return (
    <div className="w-full flex items-center justify-center ">
      <Card>
        <form onSubmit={formik.handleSubmit}>
          <h1 className="text-2xl font-bold text-center mb-10 text-black">
            Login
          </h1>
          <LabelInput
            formik={formik}
            name="email"
            label="Email"
            type="email"
            className="mb-5"
          />
          <LabelInput
            formik={formik}
            name="password"
            label="Password"
            type="password"
            className="mb-10"
          />
          <Button type="submit">Login</Button>
        </form>
      </Card>
    </div>
  );
}

export default LoginForm;
