import { useEffect, useState } from "react";
import { appAxios } from "../../api/axios";
import Pagination from "../../common/Pagination";
import UsersList from "../../components/Users/UsersList";
import { sendCatchFeedback } from "../../utils/feedback";
import { getUserSession } from "../../utils/userSession";
import AppLayout from "../../layout/AppLayout";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  closeLoadingIndicator,
  openLoadingIndicator,
} from "../../store/slices/loadingIndicator";
import ContactList from "../../components/Contact/ContactList";

function Contact() {
  const [contacts, setContacts] = useState<any[]>([]);
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const { limit } = useAppSelector((state) => state.pageLimit);

  useEffect(() => {
    const getContacts = async () => {
      const currentUser = getUserSession();

      dispatch(openLoadingIndicator({ text: "Fetching Contact Us Data" }));
      try {
        const response = await appAxios.get(
          `/contact-us/search?page=${page}&take=${limit}`,
          {
            headers: {
              Authorization: currentUser
                ? "Bearer " + currentUser?.token?.token
                : null,
            },
          }
        );
        setContacts(response.data?.data?.contactUs?.data);
        setTotalResults(response.data?.data?.contactUs?.meta?.itemCount);
      } catch (error) {
        sendCatchFeedback(error);
      }
      dispatch(closeLoadingIndicator());
    };
    getContacts();
  }, [dispatch, page, limit]);

  const nextPage = async () => {
    setPage(page + 1);
  };
  const previousPage = async () => {
    setPage(page - 1);
  };

  return (
    <AppLayout>
      <ContactList list={contacts} />
      {contacts.length > 0 && (
        <Pagination
          page={page}
          totalResults={totalResults}
          nextPageChange={nextPage}
          previousPageChange={previousPage}
        />
      )}
    </AppLayout>
  );
}

export default Contact;
