import React from "react";
import TribeCard from "./TribeCard";

function TribeList({ list }: { list: Record<string, string>[] }) {
  return (
    <div className="flex flex-col gap-5">
      {list.length > 0 ? (
        list.map((info: Record<string, string>) => (
          <TribeCard data={info} key={info._id} />
        ))
      ) : (
        <span className="text-black">No Data found</span>
      )}
    </div>
  );
}

export default TribeList;
