import React from "react";
import { DevelopType } from "../../types";
import DevelopCard from "./DevelopCard";

function DevelopList({
  develops,
  openDeleteModal,
  openViewModal,
  openEditModal,
}: {
  develops: DevelopType[];
  openDeleteModal: (d: DevelopType) => void;
  openEditModal: (d: DevelopType) => void;
  openViewModal: (d: DevelopType) => void;
}) {
  if (!develops.length)
    return <span className="text-black">No develop found</span>;
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 w-full gap-5 bg-white p-5 rounded">
      {develops.map((develop) => (
        <DevelopCard
          develop={develop}
          key={develop._id}
          openDeleteModal={openDeleteModal}
          openViewModal={openViewModal}
          openEditModal={openEditModal}
        />
      ))}
    </div>
  );
}

export default DevelopList;
