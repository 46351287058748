import { appAxios } from "../../../api/axios";
import LabelInput from "../../../common/LabelInput/LabelInput";
import { sendCatchFeedback, sendFeedback } from "../../../utils/feedback";
import {
  closeLoadingIndicator,
  openLoadingIndicator,
} from "../../../store/slices/loadingIndicator";
import { useFormik } from "formik";
import * as yup from "yup";
import { useAppDispatch } from "../../../store/hooks";
import { useNavigate } from "react-router";
import Button from "../../../common/Button/Button";
import Dropdown from "../../../common/Dropdown/Dropdown";
import { UnitType, InvestmentType, InvestmentObjType } from "../../../types";
import { getUserSession } from "../../../utils/userSession";
import { useState } from "react";
import HouseUnits from ".././HouseUnits";

function InvestmentEditForm({
  selectedInvestment,
  closeEditModal,
}: {
  selectedInvestment: InvestmentObjType | { [key: string]: any };
  closeEditModal: () => void;
}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      investedOn: selectedInvestment.investedOn.property_name,
      productPrice: selectedInvestment.product_price,
      downpayment: selectedInvestment.down_payment,
      amountDue: selectedInvestment.total_amount_due,
      amountPaid: selectedInvestment.total_amount_paid

    },
    onSubmit: () => {
      submitValues();
    },
    validationSchema: yup.object({    

    }),
  });



  const submitValues = async () => {
    console.log('i got here')
    const data = {
      total_amount_paid: formik.values.amountPaid,
      total_amount_due: formik.values.amountDue,
    };

    const currentUser = getUserSession();

    dispatch(openLoadingIndicator({ text: "Updating Investment" }));
    try {
      const response = await appAxios.patch(
        "/investments/" + selectedInvestment._id,
        data,
        {
          headers: {
            Authorization: currentUser
              ? "Bearer " + currentUser?.token?.token
              : null,
          },
        }
      );
      sendFeedback(response.data?.status, "success");

      // Update state

      closeEditModal();

      navigate("/investments");
    } catch (error) {
      sendCatchFeedback(error);
    }
    dispatch(closeLoadingIndicator());
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
      <LabelInput
          formik={formik}
          name="investedOn"
          label="Property"
          disabled={true}
          className="mb-5"
        />
        <LabelInput
          formik={formik}
          name="productPrice"
          label="Product Price"
          disabled={true}
          className="mb-5"
        />
        <LabelInput
          formik={formik}
          name="amountDue"
          label="Amount Due"
          className="mb-5"
        />
        
        <LabelInput
          formik={formik}
          name="amountPaid"
          label="Amount Paid"
          className="mb-5"
        />
        


        <Button type="submit">Update Investment</Button>
      </form>
    </>
  );
}

export default InvestmentEditForm;
