import { DevelopType, UnitType } from "../../types";
import { formatNumberToNaira } from "../../utils/stringManipulations";

function ViewDevelop({
  selectedDevelop,
}: {
  selectedDevelop: DevelopType | { [key: string]: any };
}) {
  return (
    <div>
      <img
        src={selectedDevelop.images[0]}
        alt="Develop"
        className="rounded-md h-44 w-full object-cover"
      />
      <p className="mt-5  text-2xl">
        {selectedDevelop.title} - {selectedDevelop.property_name}
      </p>
      <p className=" mb-10 text-xs text-gray-500">
        {selectedDevelop.address}, {selectedDevelop.city},{" "}
        {selectedDevelop.state}
      </p>
      <p className="mb-5 text-md">{selectedDevelop.description}</p>
      <p className="mb-5 text-md capitalize">Type: {selectedDevelop.type}</p>
      <p className="mb-5 text-md capitalize">
        Format: {selectedDevelop.format}
      </p>

      {selectedDevelop.type === "house" && (
        <div className="mb-5 border p-2 rounded">
          <p className="text-lg font-bold mb-2">Units:</p>
          {selectedDevelop.units?.map((unit: UnitType, index: number) => (
            <div className="flex flex-col gap-2 mb-4" key={unit._id}>
              <span>{index + 1}</span>
              <span>Title: {unit.title}</span>
              <span>
                Current Price: {formatNumberToNaira(unit.current_price)}
              </span>
              <span>
                Previous Price: {formatNumberToNaira(unit.previous_price || 0)}
              </span>
            </div>
          ))}
        </div>
      )}
      <p className="mb-5 text-md">Property ID: {selectedDevelop.property_id}</p>
      <p className="mb-5 text-md">Size: {selectedDevelop.size}</p>
      <p className="mb-5 text-md">
        Current price: {formatNumberToNaira(selectedDevelop.current_price)}
      </p>
      <p className="mb-5 text-md">
        Previous price: {formatNumberToNaira(selectedDevelop.previous_price)}
      </p>
      <p className="mb-5 text-md">
        Discount: {formatNumberToNaira(selectedDevelop.discount)}
      </p>
      <p className="mb-5 text-md">
        Neighborhood Features: <br /> {selectedDevelop.neighborhood?.join(", ")}
      </p>
      <p className="mb-10 text-md">
        Estate Features: <br /> {selectedDevelop.estate_features?.join(", ")}
      </p>

      <p className="mb-2 text-sm">Images</p>
      <div className="grid grid-cols-2 gap-5">
        {selectedDevelop.images?.map((image: string) => (
          <img
            src={image}
            alt=""
            className="rounded-md h-32 w-full object-cover"
            key={image}
          />
        ))}
      </div>
    </div>
  );
}

export default ViewDevelop;
