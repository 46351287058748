import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { appAxios } from "../../api/axios";
import TribeList from "../../components/Tribe/TribeList";
import Pagination from "../../common/Pagination";
import Button from "../../common/Button/Button";
import { sendCatchFeedback } from "../../utils/feedback";
import { getUserSession } from "../../utils/userSession";
import AppLayout from "../../layout/AppLayout";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  closeLoadingIndicator,
  openLoadingIndicator,
} from "../../store/slices/loadingIndicator";

function Tribe() {
  const [tribes, setTribes] = useState<any[]>([]);
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const { limit } = useAppSelector((state) => state.pageLimit);

  useEffect(() => {
    const getTribes = async () => {
      const currentUser = getUserSession();

      dispatch(openLoadingIndicator({ text: "Fetching Tribe Data" }));
      try {
        const response = await appAxios.get(
          `/join-our-tribe/search?page=${page}&take=${limit}`,
          {
            headers: {
              Authorization: currentUser
                ? "Bearer " + currentUser?.token?.token
                : null,
            },
          }
        );
        setTribes(response.data?.data?.joinTribe?.data);
        setTotalResults(response.data?.data?.joinTribe?.meta?.itemCount);
      } catch (error) {
        sendCatchFeedback(error);
      }
      dispatch(closeLoadingIndicator());
    };
    getTribes();
  }, [dispatch, page, limit]);

  const nextPage = async () => {
    setPage(page + 1);
  };
  const previousPage = async () => {
    setPage(page - 1);
  };

  return (
    <AppLayout
      pageAction={
        <div className="flex flex-row items-center flex-wrap gap-5">
          <Link to="/tribe/add-video">
            <Button className="h-10">Create Video</Button>
          </Link>
        </div>
      }
    >
      <TribeList list={tribes} />
      {tribes.length > 0 && (
        <Pagination
          page={page}
          totalResults={totalResults}
          nextPageChange={nextPage}
          previousPageChange={previousPage}
        />
      )}
    </AppLayout>
  );
}

export default Tribe;
