import Card from '../../common/Card/Card';
//@ts-ignore
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import Button from '../../common/Button/Button';
import { useState } from 'react';
import { Link } from 'react-router-dom';

//@ts-ignore
ChartJS.register(ArcElement, Tooltip, Legend);

interface Props {
	color: string;
	title: string;
	value: number | string;
	destination: string;
}

function DashboardChartItem({
	title = '',
	color = '#000',
	value = 0,
	destination = '/',
}: Props) {
	const [hover, setHover] = useState(false);

	const data = {
		labels: [],
		datasets: [
			{
				label: title,
				data: [value],
				backgroundColor: [color],
				borderColor: [`${color}90`],
				borderWidth: 1,
			},
		],
	};

	const options = {
		responsive: true,
		plugins: {
			legend: {
				display: false,
			},
		},
	};

	return (
		<Card className='w-full min-w-full flex items-center flex-col gap-5'>
			<span
				className='font-semibold text-2xl self-start font-secondary'
				style={{
					color,
				}}
			>
				{title}
			</span>
			<div className='w-full h-full relative'>
				<Doughnut data={data} width='100%' options={options} />
				<span className='absolute left-0 right-0 w-fit h-fit m-auto top-0 bottom-0 font-bold text-4xl font-secondary'>
					{value}
				</span>
			</div>
			<Link to={destination}>
				<Button
					onMouseEnter={() => {
						setHover(true);
					}}
					onMouseLeave={() => {
						setHover(false);
					}}
					className={`bg-white text-black w-[102px] text-sm border-[0.5px] rounded-[10px] h-[35px] hover:text-white font-secondary font-semibold`}
					style={{
						backgroundColor: hover ? color : '#fff',
						borderColor: color,
					}}
				>
					Expand
				</Button>
			</Link>
		</Card>
	);
}

export default DashboardChartItem;
