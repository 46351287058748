import AddGrowForm from '../../components/Grow/AddGrowForm';
import AppLayout from '../../layout/AppLayout';

function AddGrow() {
	return (
		<AppLayout pageTitle='Add Grow Product'>
			<AddGrowForm />
		</AppLayout>
	);
}

export default AddGrow;
