import React from "react";
import Card from "../../common/Card/Card";

function ThriftCard({ data }: { data: Record<string, string> }) {
  return (
    <Card className="min-w-full w-full">
      <div className="flex flex-col gap-5 text-sm text-black">
        <div className="flex flex-row items-center flex-wrap justify-between">
          <b>Name</b>
          <span>{data.name}</span>
        </div>
        <div className="flex flex-row items-center flex-wrap justify-between">
          <b>Email</b>
          <span>{data.email}</span>
        </div>
        <div className="flex flex-row items-center flex-wrap justify-between">
          <b>Phone</b>
          <span>{data.contact_phone}</span>
        </div>
        <div className="flex flex-row items-center flex-wrap justify-between">
          <b>Group Name</b>
          <span>{data.group_name}</span>
        </div>
        <div className="flex flex-row items-center flex-wrap justify-between">
          <b>Message</b>
          <span>{data.message}</span>
        </div>
        <div className="flex flex-row items-center flex-wrap justify-between">
          <b>Address</b>
          <span>{data.address}</span>
        </div>
        <div className="flex flex-row items-center flex-wrap justify-between">
          <b>Created</b>
          <span>{new Date(data.created_at).toDateString()}</span>
        </div>
      </div>
    </Card>
  );
}

export default ThriftCard;
