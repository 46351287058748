import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { appAxios } from "../../api/axios";
import Button from "../../common/Button/Button";
import CustomModal from "../../common/CustomModal/CustomModal";
import { sendCatchFeedback } from "../../utils/feedback";
import AppLayout from "../../layout/AppLayout";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  closeLoadingIndicator,
  openLoadingIndicator,
} from "../../store/slices/loadingIndicator";
import { InvestType } from "../../types";
import Pagination from "../../common/Pagination";
import { getUserSession } from "../../utils/userSession";
import InvestList from "../../components/Invest/InvestList";
import EditInvestForm from "../../components/Invest/EditInvestForm";
import DeleteInvestForm from "../../components/Invest/DeleteInvestForm";
import ViewInvest from "../../components/Invest/ViewInvest";

function Invest() {
  const [invests, setInvests] = useState<InvestType[]>([]);
  const dispatch = useAppDispatch();
  const [selectedInvest, setSelectedInvest] = useState<
    InvestType | { [key: string]: any }
  >({});
  const [page, setPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const { limit } = useAppSelector((state) => state.pageLimit);

  const getInvest = async () => {
    const currentUser = getUserSession();

    dispatch(openLoadingIndicator({ text: "Fetching Invest" }));
    try {
      const response = await appAxios.get(
        `/invests?page=${page}&take=${limit}`,
        {
          headers: {
            Authorization: currentUser
              ? "Bearer " + currentUser?.token?.token
              : null,
          },
        }
      );
      console.log(response);
      setInvests(response.data?.data?.invests?.data);
      setTotalResults(response.data?.data?.invests?.meta?.itemCount);
    } catch (error) {
      sendCatchFeedback(error);
    }
    dispatch(closeLoadingIndicator());
  };

  useEffect(() => {
    getInvest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page, limit]);

  const nextPage = async () => {
    setPage(page + 1);
  };
  const previousPage = async () => {
    setPage(page - 1);
  };

  // delete modal
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const openDeleteModal = (invests: InvestType) => {
    setSelectedInvest(invests);
    setDeleteModalOpen(true);
  };
  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  // view modal
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const openViewModal = (invests: InvestType) => {
    setSelectedInvest(invests);
    setViewModalOpen(true);
  };
  const closeViewModal = () => {
    setViewModalOpen(false);
  };

  // edit modal
  const [editModalOpen, setEditModalOpen] = useState(false);
  const openEditModal = (invests: InvestType) => {
    setSelectedInvest(invests);
    setEditModalOpen(true);
  };
  const closeEditModal = () => {
    setEditModalOpen(false);
  };

  return (
    <AppLayout
      pageAction={
        <div className="flex flex-row items-center flex-wrap gap-5">
          <Link to="/invest/requests">
            <Button className="h-10 bg-dark">View Requests</Button>
          </Link>
          <Link to="/invest/add">
            <Button className="h-10">Add Invest</Button>
          </Link>
        </div>
      }
    >
      <InvestList
        invests={invests}
        openDeleteModal={openDeleteModal}
        openViewModal={openViewModal}
        openEditModal={openEditModal}
      />
      {invests.length > 0 && (
        <Pagination
          page={page}
          totalResults={totalResults}
          nextPageChange={nextPage}
          previousPageChange={previousPage}
        />
      )}

      {/* Edit Modal */}
      <CustomModal
        modalState={editModalOpen}
        closeModal={closeEditModal}
        title="Edit Develop"
      >
        <EditInvestForm
          selectedInvest={selectedInvest}
          closeEditModal={closeEditModal}
          setInvests={setInvests}
          invests={invests}
        />
      </CustomModal>

      {/* Delete Modal */}
      <CustomModal
        modalState={deleteModalOpen}
        closeModal={closeDeleteModal}
        title="Delete Develop"
      >
        <DeleteInvestForm
          selectedInvest={selectedInvest}
          closeDeleteModal={closeDeleteModal}
          setInvests={setInvests}
          invests={invests}
        />
      </CustomModal>

      {/* View Modal */}
      <CustomModal
        modalState={viewModalOpen}
        closeModal={closeViewModal}
        title="Invest Details"
      >
        <ViewInvest selectedInvest={selectedInvest} />
      </CustomModal>
    </AppLayout>
  );
}

export default Invest;
