import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { appAxios } from "../../api/axios";
import Button from "../../common/Button/Button";
import CustomModal from "../../common/CustomModal/CustomModal";
import DeleteForm from "../../components/Develops/DeleteForm";
import DevelopList from "../../components/Develops/DevelopList";
import EditForm from "../../components/Develops/EditForm";
import ViewDevelop from "../../components/Develops/ViewDevelop";
import { sendCatchFeedback } from "../../utils/feedback";
import AppLayout from "../../layout/AppLayout";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  closeLoadingIndicator,
  openLoadingIndicator,
} from "../../store/slices/loadingIndicator";
import { DevelopType } from "../../types";
import Pagination from "../../common/Pagination";
import { getUserSession } from "../../utils/userSession";

function Develops() {
  const [develops, setDevelops] = useState<DevelopType[]>([]);
  const dispatch = useAppDispatch();
  const [selectedDevelop, setSelectedDevelop] = useState<
    DevelopType | { [key: string]: any }
  >({});
  const [page, setPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const { limit } = useAppSelector((state) => state.pageLimit);

  const getDevelops = async () => {
    const currentUser = getUserSession();

    dispatch(openLoadingIndicator({ text: "Fetching Develops" }));
    try {
      const response = await appAxios.get(
        `/develops?page=${page}&take=${limit}`,
        {
          headers: {
            Authorization: currentUser
              ? "Bearer " + currentUser?.token?.token
              : null,
          },
        }
      );
      setDevelops(response.data?.data?.develops?.data);
      setTotalResults(response.data?.data?.develops?.meta?.itemCount);
    } catch (error) {
      sendCatchFeedback(error);
    }
    dispatch(closeLoadingIndicator());
  };

  useEffect(() => {
    getDevelops();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page, limit]);

  const nextPage = async () => {
    setPage(page + 1);
  };
  const previousPage = async () => {
    setPage(page - 1);
  };

  // delete modal
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const openDeleteModal = (develop: DevelopType) => {
    setSelectedDevelop(develop);
    setDeleteModalOpen(true);
  };
  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  // view modal
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const openViewModal = (develop: DevelopType) => {
    setSelectedDevelop(develop);
    setViewModalOpen(true);
  };
  const closeViewModal = () => {
    setViewModalOpen(false);
  };

  // edit modal
  const [editModalOpen, setEditModalOpen] = useState(false);
  const openEditModal = (develop: DevelopType) => {
    setSelectedDevelop(develop);
    setEditModalOpen(true);
  };
  const closeEditModal = () => {
    setEditModalOpen(false);
  };

  return (
    <AppLayout
      pageAction={
        <div className="flex flex-row items-center flex-wrap gap-5">
          <Link to="/develops/requests">
            <Button className="h-10 bg-dark">View Requests</Button>
          </Link>
          <Link to="/develops/add">
            <Button className="h-10">Add Develop</Button>
          </Link>
        </div>
      }
    >
      <DevelopList
        develops={develops}
        openDeleteModal={openDeleteModal}
        openViewModal={openViewModal}
        openEditModal={openEditModal}
      />
      {develops.length > 0 && (
        <Pagination
          page={page}
          totalResults={totalResults}
          nextPageChange={nextPage}
          previousPageChange={previousPage}
        />
      )}

      {/* Edit Modal */}
      <CustomModal
        modalState={editModalOpen}
        closeModal={closeEditModal}
        title="Edit Develop"
      >
        <EditForm
          selectedDevelop={selectedDevelop}
          closeEditModal={closeEditModal}
          setDevelops={setDevelops}
          develops={develops}
        />
      </CustomModal>

      {/* Delete Modal */}
      <CustomModal
        modalState={deleteModalOpen}
        closeModal={closeDeleteModal}
        title="Delete Develop"
      >
        <DeleteForm
          selectedDevelop={selectedDevelop}
          closeDeleteModal={closeDeleteModal}
          setDevelops={setDevelops}
          develops={develops}
        />
      </CustomModal>

      {/* View Modal */}
      <CustomModal
        modalState={viewModalOpen}
        closeModal={closeViewModal}
        title="Develop Details"
      >
        <ViewDevelop selectedDevelop={selectedDevelop} />
      </CustomModal>
    </AppLayout>
  );
}

export default Develops;
