import { useEffect, useState } from "react";
import { appAxios } from "../../../api/axios";
import CustomModal from "../../../common/CustomModal/CustomModal";
import Pagination from "../../../common/Pagination";
import DevelopDeletePropertyForm from "../../../components/Product/DevelopProperty/DevelopDeletePropertyForm";
import DevelopEditPropertyForm from "../../../components/Product/DevelopProperty/DevelopEditPropertyForm";
import DevelopPropertyList from "../../../components/Product/DevelopProperty/DevelopPropertyList";
import DevelopViewProperty from "../../../components/Product/DevelopProperty/DevelopViewProperty";
import { sendCatchFeedback } from "../../../utils/feedback";
import { getUserSession } from "../../../utils/userSession";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  closeLoadingIndicator,
  openLoadingIndicator,
} from "../../../store/slices/loadingIndicator";
import { GrowType } from "../../../types";

export const ProductDevelop = () => {
  const [developProperty, setDevelopProperty] = useState<GrowType[]>([]);
  const dispatch = useAppDispatch();
  const [selectedDevelopProperty, setSelectedDevelopProperty] = useState<
    GrowType | { [key: string]: any }
  >({});
  const [page, setPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const { limit } = useAppSelector((state) => state.pageLimit);

  const getDevelopProperty = async () => {
    const currentUser = getUserSession();

    dispatch(
      openLoadingIndicator({ text: "Fetching Develop Property Product" })
    );
    try {
      const response = await appAxios.get(
        `/develops?page=${page}&take=${limit}`,
        {
          headers: {
            Authorization: currentUser
              ? "Bearer " + currentUser?.token?.token
              : null,
          },
        }
      );
      console.log(response);
      setDevelopProperty(response.data?.data?.develops?.data);
      setTotalResults(response.data?.data?.develops.meta?.itemCount);
    } catch (error) {
      sendCatchFeedback(error);
    }
    dispatch(closeLoadingIndicator());
  };

  useEffect(() => {
    getDevelopProperty();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page, limit]);

  const nextPage = async () => {
    setPage(page + 1);
  };
  const previousPage = async () => {
    setPage(page - 1);
  };

  // delete modal
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const openDeleteModal = (invest: GrowType) => {
    setSelectedDevelopProperty(invest);
    setDeleteModalOpen(true);
  };
  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  // view modal
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const openViewModal = (invest: GrowType) => {
    setSelectedDevelopProperty(invest);
    setViewModalOpen(true);
  };
  const closeViewModal = () => {
    setViewModalOpen(false);
  };

  // edit modal
  const [editModalOpen, setEditModalOpen] = useState(false);
  const openEditModal = (develop: GrowType) => {
    setSelectedDevelopProperty(develop);
    setEditModalOpen(true);
  };
  const closeEditModal = () => {
    setEditModalOpen(false);
  };
  return (
    <>
      <DevelopPropertyList
        developProperty={developProperty}
        openDeleteModal={openDeleteModal}
        openViewModal={openViewModal}
        openEditModal={openEditModal}
      />
      {developProperty.length > 0 && (
        <Pagination
          page={page}
          totalResults={totalResults}
          nextPageChange={nextPage}
          previousPageChange={previousPage}
        />
      )}

      {/* Edit Modal */}
      <CustomModal
        modalState={editModalOpen}
        closeModal={closeEditModal}
        title="Edit invest Property"
      >
        <DevelopEditPropertyForm
          selectedDevelopProperty={selectedDevelopProperty}
          closeEditModal={closeEditModal}
          setDevelopProperty={setDevelopProperty}
          developProperty={developProperty}
        />
      </CustomModal>

      {/* Delete Modal */}
      <CustomModal
        modalState={deleteModalOpen}
        closeModal={closeDeleteModal}
        title="Delete Develop Property"
      >
        <DevelopDeletePropertyForm
          selectedDevelopProperty={selectedDevelopProperty}
          closeDeleteModal={closeDeleteModal}
          setDevelopProperty={setDevelopProperty}
          developProperty={developProperty}
        />
      </CustomModal>

      {/* View Modal */}
      <CustomModal
        modalState={viewModalOpen}
        closeModal={closeViewModal}
        title="Invest Property Details"
      >
        <DevelopViewProperty
          selectedDevelopProperty={selectedDevelopProperty}
        />
      </CustomModal>
    </>
  );
};
