import { useEffect, useState } from "react";
import { appAxios } from "../../../api/axios";
import CustomModal from "../../../common/CustomModal/CustomModal";
import Pagination from "../../../common/Pagination";
import GrowDeletePropertyForm from "../../../components/Product/GrowProperty/GrowDeletePropertyForm";
import GrowEditPropertyForm from "../../../components/Product/GrowProperty/GrowEditPropertyForm";
import GrowPropertyList from "../../../components/Product/GrowProperty/GrowPropertyList";
import GrowViewProperty from "../../../components/Product/GrowProperty/GrowViewProperty";
import { sendCatchFeedback } from "../../../utils/feedback";
import { getUserSession } from "../../../utils/userSession";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  closeLoadingIndicator,
  openLoadingIndicator,
} from "../../../store/slices/loadingIndicator";
import { GrowType } from "../../../types";

export const ProductGrow = () => {
  const [growProperty, setGrowProperty] = useState<GrowType[]>([]);
  const dispatch = useAppDispatch();
  const [selectedGrowProperty, setSelectedGrowProperty] = useState<
    GrowType | { [key: string]: any }
  >({});
  const [page, setPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const { limit } = useAppSelector((state) => state.pageLimit);

  const getGrowProperty = async () => {
    const currentUser = getUserSession();

    dispatch(openLoadingIndicator({ text: "Fetching Grow Property Product" }));
    try {
      const response = await appAxios.get(`/grows?page=${page}&take=${limit}`, {
        headers: {
          Authorization: currentUser
            ? "Bearer " + currentUser?.token?.token
            : null,
        },
      });
      console.log(response);
      setGrowProperty(response.data?.data?.grows?.data);
      setTotalResults(response.data?.data?.grows.meta?.itemCount);
    } catch (error) {
      sendCatchFeedback(error);
    }
    dispatch(closeLoadingIndicator());
  };

  useEffect(() => {
    getGrowProperty();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page, limit]);

  const nextPage = async () => {
    setPage(page + 1);
  };
  const previousPage = async () => {
    setPage(page - 1);
  };

  // delete modal
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const openDeleteModal = (grow: GrowType) => {
    setSelectedGrowProperty(grow);
    setDeleteModalOpen(true);
  };
  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  // view modal
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const openViewModal = (grow: GrowType) => {
    setSelectedGrowProperty(grow);
    setViewModalOpen(true);
  };
  const closeViewModal = () => {
    setViewModalOpen(false);
  };

  // edit modal
  const [editModalOpen, setEditModalOpen] = useState(false);
  const openEditModal = (grow: GrowType) => {
    setSelectedGrowProperty(grow);
    setEditModalOpen(true);
  };
  const closeEditModal = () => {
    setEditModalOpen(false);
  };
  return (
    <>
      <GrowPropertyList
        growProperty={growProperty}
        openDeleteModal={openDeleteModal}
        openViewModal={openViewModal}
        openEditModal={openEditModal}
      />
      {growProperty.length > 0 && (
        <Pagination
          page={page}
          totalResults={totalResults}
          nextPageChange={nextPage}
          previousPageChange={previousPage}
        />
      )}

      {/* Edit Modal */}
      <CustomModal
        modalState={editModalOpen}
        closeModal={closeEditModal}
        title="Edit invest Property"
      >
        <GrowEditPropertyForm
          selectedGrowProperty={selectedGrowProperty}
          closeEditModal={closeEditModal}
          setGrowProperty={setGrowProperty}
          growProperty={growProperty}
        />
      </CustomModal>

      {/* Delete Modal */}
      <CustomModal
        modalState={deleteModalOpen}
        closeModal={closeDeleteModal}
        title="Delete Invest Property"
      >
        <GrowDeletePropertyForm
          selectedGrowProperty={selectedGrowProperty}
          closeDeleteModal={closeDeleteModal}
          setGrowProperty={setGrowProperty}
          growProperty={growProperty}
        />
      </CustomModal>

      {/* View Modal */}
      <CustomModal
        modalState={viewModalOpen}
        closeModal={closeViewModal}
        title="grow Property Details"
      >
        <GrowViewProperty selectedGrowProperty={selectedGrowProperty} />
      </CustomModal>
    </>
  );
};
