import Contact from "../../pages/Contact";
import { checkPrivateRoute } from "../utils";

export const contactRoutes = [
  {
    path: "/contact",
    element: <Contact />,
    loader: checkPrivateRoute,
  },
];
