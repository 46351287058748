import React from "react";
import { tW } from "../../utils";

function Button({
  className,
  type = "button",
  onClick,
  children,
  ...rest
}: {
  className?: string;
  type?: "button" | "submit" | "reset" | undefined;
  onClick?: () => void;
  children: React.ReactNode;
  [x: string]: any;
}) {
  return (
    <button
      type={type}
      className={tW(
        "bg-primary text-white h-[60px] p-5 rounded-md w-full font-medium text-md flex items-center justify-center hover:brightness-110 duration-500 ",
        className
      )}
      onClick={onClick}
      {...rest}
    >
      {children}
    </button>
  );
}

export default Button;
