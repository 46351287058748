import React from 'react';
import CSVImage from '../../../assets/icons/csv.svg';
import { CSVLink, CSVDownload } from "react-csv";

function CSVButton({ data }: any) {
    console.log(data);
    const headers = [
        {label: "Email", key: 'email'},
        {label: "Name", key: 'name'},
        {label: "Phone Number", key: 'phone_number'},
        {label: "Quantity", key: 'quantity'},
        {label: "DOB", key: 'date_of_birth'},
        {label: "Duration", key: 'duration'},
        {label: "Account Name", key: 'account_name'},
        {label: "Account Number", key: 'account_number'},
        {label: "Bank Name", key: 'bank_name'},

        {label: "Title", key: 'invest.title'},
        {label: "Property Name", key: 'invest.property_name'},
        {label: "Property Address", key: 'invest.address'},
        {label: "Property City", key: 'invest.city'},
        {label: "Property Country", key: 'invest.country'},


        {label: "Current Price", key: 'invest.current_price'},
        {label: "Inspection", key: 'inspection'},

    ]
    return (


        <CSVLink data={data} filename={"Invest-requests.csv"} headers={headers}>
            <button
                className='p-2 pl-5 pr-5 hover:bg-dark bg-lightDark rounded flex gap-2 text-white'
            >
                <img src={CSVImage} alt='' />CSV
            </button>

        </CSVLink>
    );
}

export default CSVButton;
