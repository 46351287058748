import React from "react";
import { DevelopRequestType, EstateProType } from "../../types";
import Card from "../../common/Card/Card";

function EstateProCard({ request }: { request: EstateProType }) {
  return (
    <Card className="min-w-full w-full">
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="border-b-2 md:border-b-0 md:border-r-2 border-lightGrey md:pr-5 mb-5 md:mb-0 pb-5 md:pb-0">
          <p className="mb-5 text-black font-medium text-lg mt-2 md:mt-0">
            Request
          </p>
          <div className="flex flex-row items-center flex-wrap justify-between mb-2 text-black text-sm">
            <b>First Name</b>
            <span className="capitalize">{request.first_name}</span>
          </div>
          <div className="flex flex-row items-center flex-wrap justify-between mb-2 text-black text-sm">
            <b>Last Name</b>
            <span className="capitalize">{request.last_name}</span>
          </div>
          <div className="flex flex-row items-center flex-wrap justify-between mb-2 text-black text-sm">
            <b>Email</b>
            <span>{request.email}</span>
          </div>
          <div className="flex flex-row items-center flex-wrap justify-between mb-2 text-black text-sm">
            <b>Phone</b>
            <span>{request.phone}</span>
          </div>
          <div className="flex flex-row items-center flex-wrap justify-between mb-2 text-black text-sm">
            <b>Residential Address</b>
            <span>{request.residential_address}</span>
          </div>
          <div className="flex flex-row items-center flex-wrap justify-between mb-2 text-black text-sm">
            <b>Whatsapp Number</b>
            <span>{request.whatsapp_number}</span>
          </div>
          <div className="flex flex-row items-center flex-wrap justify-between mb-2 text-black text-sm">
            <b>Repayment Format</b>
            <span>{request.repayment_format}</span>
          </div>
          <div className="flex flex-row items-center flex-wrap justify-between mb-2 text-black text-sm">
            <b>Repayment Duration</b>
            <span>{request.repayment_duration}</span>
          </div>
          <div className="flex flex-row items-center flex-wrap justify-between mb-2 text-black text-sm">
            <b>Number of Plots</b>
            <span className="capitalize">{request.number_of_plots}</span>
          </div>
          <div className="flex flex-row items-center flex-wrap justify-between mb-2 text-black text-sm">
            <b>Construction Amount</b>
            <span className="capitalize">{request.construction_amount}</span>
          </div>
          <div className="flex flex-row items-center flex-wrap justify-between mb-2 text-black text-sm">
            <b>Repayment Amount</b>
            <span className="capitalize">{request.repayment_amount}</span>
          </div>

          <div className="flex flex-row items-center flex-wrap justify-between mb-2 text-black text-sm">
            <b>Date</b>
            <span>{new Date(request.created_at).toDateString()}</span>
          </div>
        </div>

        {/* <div className="md:pl-5">
       
          <p className="mb-5 text-black font-medium text-lg mt-2 md:mt-0">
            DevPro
          </p>
          
        <img
            src={request.develop.images[0]}
            alt=""
            className="h-32 w-full object-cover rounded-md mb-2"
          />
          <p className="mb-2 text-black text-sm text-md">
            {request.develop.title} - {request.develop.property_name}
          </p>
          <p className="text-gray-400 text-xs">
            {request.develop.address}, {request.develop.state}
          </p> 
        
        </div> */}
      </div>
    </Card>
  );
}

export default EstateProCard;
