import React, { useState, useEffect } from "react";
import AppLayout from "../../layout/AppLayout";
import { getUserSession } from "../../utils/userSession";
import { appAxios } from "../../api/axios";
import { InvestmentObjType, InvestmentType } from "../../types";
import Button from "../../common/Button/Button";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  closeLoadingIndicator,
  openLoadingIndicator,
} from "../../store/slices/loadingIndicator";
import Pagination from "../../common/Pagination";
import CustomModal from "../../common/CustomModal/CustomModal";
import InvestmentDetails from "../../components/Invest/Investment/InvestmentDetails";
import InvestmentEditForm from "../../components/Invest/Investment/InvestmentEditForm";

const Investments = () => {
  const dispatch = useAppDispatch();
  const [data, setData] = useState<InvestmentType>();
  const [page, setPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const { limit } = useAppSelector((state) => state.pageLimit);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [idd, setIdd] = useState("");
  const [details, setDetails] = useState<
    InvestmentObjType | { [key: string]: any }
  >({});

  const closeViewModal = () => {
    setViewModalOpen(false);
    setOpenEditModal(false);
  };

  const fetchInvestList = async () => {
    dispatch(openLoadingIndicator({ text: "Fetching Investment" }));
    try {
      const currentUser = getUserSession();

      const response: InvestmentType = await appAxios.get(
        `/investments?page=${page}&take=${limit}`,
        {
          headers: {
            Authorization: currentUser
              ? "Bearer " + currentUser?.token?.token
              : null,
          },
        }
      );
      console.log(response);
      setData(response);
      setTotalResults(response.data?.data?.investments?.meta?.itemCount);
    } catch (error) {
      console.log(error);
    }
    dispatch(closeLoadingIndicator());
  };

  const nextPage = async () => {
    setPage(page + 1);
  };
  const previousPage = async () => {
    setPage(page - 1);
  };

  const viewEachInvestment = (id: string, d: InvestmentObjType) => {
    console.log(d);
    setViewModalOpen(true);
    setIdd(id);
    setDetails(d);
  };

  const editEachInvestment = (id: string, d: InvestmentObjType) => {
    console.log(d);
    setOpenEditModal(true);
    setIdd(id);
    setDetails(d);
  };

  useEffect(() => {
    fetchInvestList();
  }, [dispatch, page, limit]);

  return (
    <AppLayout>
      <div>
        <table
          className="
        xl:border-collapse:collapse xl:w-full 
        lg:border-collapse:collapse lg:w-full lg:table-auto lg:overflow-x-scroll lg:block
        md:border-collapse:collapse md:w-full md:table-auto md:overflow-x-scroll md:block
        sm:border-collapse:collapse sm:w-full sm:table-auto sm:overflow-x-scroll sm:block 
        xs:border-collapse:collapse xs:w-full xs:table-auto xs:overflow-x-scroll xs:block
    
    "
        >
          <thead>
            <tr className="border border-[#dddddd] text-left p-4">
              <th className="border border-[#dddddd] text-left p-4">
                Name
              </th>
              <th className="border border-[#dddddd] text-left p-4">
                Product Type
              </th>
              <th className="border border-[#dddddd] text-left p-4">
                Product
              </th>
              <th className="border border-[#dddddd] text-left p-4">
                Quantity
              </th>
              <th className="border border-[#dddddd] text-left p-4">
                Subscription Date
              </th>
              <th className="border border-[#dddddd] text-left p-4">
                Payment Plan
              </th>
              {/* <th className='border border-[#dddddd] text-left p-4'>Down Payment</th>
                    <th className='border border-[#dddddd] text-left p-4'>Down Payment Rate</th> */}
              <th className="border border-[#dddddd] text-left p-4">
                Payment Status
              </th>
              <th className="border border-[#dddddd] text-left p-4">
                Total Amount
              </th>
              <th className="border border-[#dddddd] text-left p-4">
                Pending Amount
              </th>
              <th className="border border-[#dddddd] text-left p-4">Action</th>
            </tr>
          </thead>
          <tbody>
            {data?.data.data.investments?.data.map((x, i) => (
              <tr className="border border-[#dddddd] text-left p-4" key={x._id}>
                <td className="border border-[#dddddd] text-left p-4 text-black">
                  {`${x.user_id?.first_name} ${x.user_id?.last_name}`}
                </td>
                <td className="border border-[#dddddd] text-left p-4 text-black">
                  {x.product_type}
                </td>
                <td className="border border-[#dddddd] text-left p-4 text-black">
                  {x.investedOn?.title}
                </td>
                <td className="border border-[#dddddd] text-left p-4 text-black">
                  {x.quantity}
                </td>
                <td className="border border-[#dddddd] text-left p-4 text-black">
                  {new Date(x.date_of_investment).toDateString()}
                </td>
                <td className="border border-[#dddddd] text-left p-4 text-black">
                  {x.repayment_cycle}
                </td>

                

                <td className="border border-[#dddddd] text-left p-4 text-black">
                  {x.payment_status}
                </td>
                <td className="border border-[#dddddd] text-left p-4 text-black">
                  {x.product_price}
                </td>
                <td className="border border-[#dddddd] text-left p-4 text-black">
                  {x.total_amount_due}
                </td>
                <td className="border border-[#dddddd] text-left p-4 text-black  flex flex-col gap-y-2">
                  <Button
                    className="!h-6 p-1 pl-3 pr-3 text-xs w-[63px] bg-white border border-[#666666] text-[#666666]"
                    onClick={() => viewEachInvestment(x._id, x)}
                  >
                    View
                  </Button>
                  <Button className="!h-6 p-1 pl-3 pr-3 text-xs w-[63px] bg-white border border-[#666666] text-[#666666]"
                  onClick={() => editEachInvestment(x._id, x)}>
                    Edit
                  </Button>
                  <Button className="!h-6 p-1 pl-3 pr-3 text-xs w-[63px] bg-error  text-white">
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {data?.data?.data?.investments?.data?.length && (
          <Pagination
            page={page}
            totalResults={totalResults}
            nextPageChange={nextPage}
            previousPageChange={previousPage}
          />
        )}

        <CustomModal
          modalState={viewModalOpen}
          closeModal={closeViewModal}
          title="Investment Details"
        >
          <InvestmentDetails detail={details} />
        </CustomModal>

        <CustomModal
          modalState={openEditModal}
          closeModal={closeViewModal}
          title="Investment Edit"
        >
          <InvestmentEditForm selectedInvestment={details} closeEditModal={closeViewModal}   />
        </CustomModal>
      </div>
    </AppLayout>
  );
};

export default Investments;
