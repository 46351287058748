import Invest from '../../pages/Invest';
import AddInvest from '../../pages/Invest/add';
import { checkPrivateRoute } from '../utils';

export const investRoutes = [
	{
		path: '/invest',
		element: <Invest />,
		loader: checkPrivateRoute,
	},
	{
		path: '/invest/add',
		element: <AddInvest />,
		loader: checkPrivateRoute,
	},
];
