import React from "react";
import Card from "../../common/Card/Card";

function TribeCard({ data }: { data: Record<string, string> }) {
  return (
    <Card className="min-w-full w-full">
      <div className="flex flex-col gap-5 text-sm text-black">
        <div className="flex flex-row items-center flex-wrap justify-between">
          <b>Name</b>
          <span>
            {data.first_name} {data.last_name}
          </span>
        </div>
        <div className="flex flex-row items-center flex-wrap justify-between">
          <b>Email</b>
          <span>{data.email}</span>
        </div>
        <div className="flex flex-row items-center flex-wrap justify-between">
          <b>Phone</b>
          <span>{data.whatsapp_no}</span>
        </div>
        <div className="flex flex-row items-center flex-wrap justify-between">
          <b>Created</b>
          <span>{new Date(data.created_at).toDateString()}</span>
        </div>
      </div>
    </Card>
  );
}

export default TribeCard;
