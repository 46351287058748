import Button from '../../common/Button/Button'
import { previewGrowType } from '../../types'


const PaymentPlan = ({ growPlan , growInvestment , developInvestment  }: { growPlan : previewGrowType  | { [key: string]: any } , growInvestment : () => void , developInvestment : () => void}) => {
  console.log(growPlan)
  
  return (
	<div>
     
			<p className='mt-5  text-2xl'>
				Product Name : {growPlan?.data?.data?.product_name}
			</p>
			<p className='mt-5  text-2xl'>
				Product Price : {growPlan?.data?.data?.product_price}
			</p>
			<p className='mt-5  text-2xl'>
				Product Type : {growPlan?.data?.data?.product_type}
			</p>
			<p className='mt-5  text-2xl'>
				Repayment Amount : {growPlan?.data?.data?.repayment_amount}
			</p>
			<p className='mt-5  text-2xl'>
				Total Repayment : {growPlan?.data?.data?.total_repayment}
			</p>
			<p className='mt-5  text-2xl'>
				Duration : {growPlan?.data?.data?.duration}
			</p>
		    
			<p className='mt-5  text-2xl'>
				<Button
				type = "submit"
				onClick={growPlan?.data?.data?.product_type === 'Grow' ? growInvestment : developInvestment }
				>Submit</Button>
			</p>
			
			
	</div>
  )
}

export default PaymentPlan