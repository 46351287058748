import { GrowRequestType } from "../../../types";
import RequestCard from "./RequestCard";

function RequestsList({ requests }: { requests: GrowRequestType[] }) {
  return (
    <div className="flex flex-col gap-10">
      {requests?.length > 0 ? (
        requests?.map((request: GrowRequestType) => (
          <RequestCard request={request} key={request._id} />
        ))
      ) : (
        <span className="text-black">No request found</span>
      )}
    </div>
  );
}

export default RequestsList;
