import { GrowType } from "../../../types";
import DevelopPropertyCard from "./DevelopPropertyCard";

function DevelopPropertyList({
  developProperty,
  openDeleteModal,
  openViewModal,
  openEditModal,
}: {
  developProperty: GrowType[];
  openDeleteModal: (d: GrowType) => void;
  openEditModal: (d: GrowType) => void;
  openViewModal: (d: GrowType) => void;
}) {
  if (!developProperty.length)
    return <span className="text-black">No develop product found</span>;
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 w-full gap-5 bg-white p-5 rounded mt-5">
      {developProperty.map((inProperty) => (
        <DevelopPropertyCard
          developProperty={inProperty}
          key={inProperty._id}
          openDeleteModal={openDeleteModal}
          openViewModal={openViewModal}
          openEditModal={openEditModal}
        />
      ))}
    </div>
  );
}

export default DevelopPropertyList;
