import LoginForm from '../components/Login/LoginForm';
import AppLayout from '../layout/AppLayout';

function Login() {
	return (
		<AppLayout showPageHeader={false}>
			<div className='min-h-main flex items-center justify-center dark:bg-mediumDark'>
				<LoginForm />
			</div>
		</AppLayout>
	);
}

export default Login;
