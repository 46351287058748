import Tribe from "../../pages/Tribe";
import AddVideo from "../../pages/Tribe/addVideo";
import { checkPrivateRoute } from "../utils";

export const tribeRoutes = [
  {
    path: "/tribe",
    element: <Tribe />,
    loader: checkPrivateRoute,
  },
  {
    path: "/tribe/add-video",
    element: <AddVideo />,
    loader: checkPrivateRoute,
  },
];
