import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import Card from '../../common/Card/Card';

interface Props {
	title: string;
	value: string | number;
	color: string;
}

function DashboardSummaryCard({ title = '', value = 0, color = '#000' }: Props) {
	const data = {
		labels: [],
		datasets: [
			{
				label: title,
				data: [value],
				backgroundColor: [color],
				borderColor: [`${color}90`],
				borderWidth: 1,
			},
		],
	};

	const options = {
		responsive: true,
		plugins: {
			legend: {
				display: false,
			},
		},
	};
	return (
		<Card className='!p-5 min-w-full'>
			<div className='flex flex-row flex-wrap gap-5 items-center justify-between'>
				<div className='flex flex-col gap-[5px]'>
					<span className='text-[#808191] text-sm'>{title}</span>
					<span className='font-bold text-2xl text-[#11142D]'>{value}</span>
				</div>
				<div>
					<Doughnut data={data} options={options} height={60} width={60} />
				</div>
			</div>
		</Card>
	);
}

export default DashboardSummaryCard;
