import { FormEvent, useMemo, useState } from "react";
import { appAxios } from "../../api/axios";
import Card from "../../common/Card/Card";
import { sendCatchFeedback, sendFeedback } from "../../utils/feedback";
import {
  closeLoadingIndicator,
  openLoadingIndicator,
} from "../../store/slices/loadingIndicator";
import { useFormik } from "formik";
import * as yup from "yup";
import { useAppDispatch } from "../../store/hooks";
import { useNavigate } from "react-router";
import Button from "../../common/Button/Button";
import { getUserSession } from "../../utils/userSession";

interface UnitType {
  [id: string]: {
    id: string;
    title: string;
    current_price: number;
    previous_price: number | undefined;
  };
}

function AddVideoForm() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentUser = getUserSession();

  const formik = useFormik({
    initialValues: {
      developImages: [],
      imagesUploaded: false,
      imagePaths: [],
    },
    onSubmit: () => {
      submitVideoHandler();
    },
  });

  const submitVideoHandler = async () => {
    try {
      const response = await appAxios.post(
        "/settings",
        { video_link: formik.values.developImages[0] },
        {
          headers: {
            Authorization: currentUser
              ? "Bearer " + currentUser?.token?.token
              : null,
          },
        }
      );

      sendFeedback(response.data?.status, "success");
    } catch (error) {
      sendCatchFeedback(error);
    } finally {
      dispatch(closeLoadingIndicator());
    }
  };

  const uploadImages = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!formik.values.developImages.length) {
      return sendFeedback("Select at least one image", "error");
    }

    try {
      dispatch(openLoadingIndicator({ text: "Uploading" }));

      const formData = new FormData();

      // append images
      formik.values.developImages.map((file) => formData.append("files", file));

      const response = await appAxios.post("/upload/files", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: currentUser
            ? "Bearer " + currentUser?.token?.token
            : null,
        },
      });

      formik.setFieldValue("imagePaths", response.data?.data?.filePaths);
      formik.setFieldValue("imagesUploaded", true);
      formik.handleSubmit();

      // document.getElementById("details")?.scrollIntoView();
    } catch (error) {
      sendCatchFeedback(error);
      dispatch(closeLoadingIndicator());
    }
  };

  return (
    <>
      <div
        style={{
          pointerEvents: !formik.values.imagesUploaded ? "auto" : "none",
          opacity: !formik.values.imagesUploaded ? 1 : 0.1,
        }}
      >
        <Card className="w-full min-w-full mb-20">
          <form onSubmit={uploadImages}>
            <h1 className="text-2xl font-bold text-center mb-10 text-black">
              Upload Video
            </h1>

            <div className="flex flex-col gap-2 mb-5">
              <label htmlFor="coralVideo" className="text-black">
                Select Video for Coral
              </label>
              <input
                type="file"
                name="coralVideo"
                id="coralVideo"
                multiple
                className="border-lightGrey border-2 text-black"
                accept="video/mp4,video/x-m4v,video/*"
                onChange={(e: any) => {
                  const file = Array.prototype.slice.call(e.target.files);
                  formik.setFieldValue("developImages", file);
                }}
              />
            </div>
            <Button type="submit">Upload Video</Button>
          </form>
        </Card>
      </div>
    </>
  );
}

export default AddVideoForm;
