import Button from "../../common/Button/Button";
import LocationImage from "../../assets/icons/location.png";
import { formatNumberToNaira } from "../../utils/stringManipulations";
import { DevelopType } from "../../types";

function DevelopCard({
  develop,
  openDeleteModal,
  openViewModal,
  openEditModal,
}: {
  develop: DevelopType;
  openDeleteModal: (d: DevelopType) => void;
  openViewModal: (d: DevelopType) => void;
  openEditModal: (d: DevelopType) => void;
}) {
  return (
    <div className="flex flex-col items-stretch gap-2">
      <img
        src={develop.images[0]}
        alt="Develop"
        className="w-full h-[186px] rounded-md object-cover"
      />
      <div className="flex flex-col gap-5">
        <div className="flex flex-col gap-2">
          <div className="flex items-start justify-between">
            <span className="font-medium ">{develop.title} </span>
            <div className="p-2 rounded text-sm bg-[#F0EEFF] text-[#6C5DD3]">
              {formatNumberToNaira(develop.current_price)}{" "}
            </div>
          </div>
          <div className="flex items-center gap-2 mb-[17px]">
            <img src={LocationImage} alt="Location" />
            <span className="line-clamp  text-sm">{develop.state} </span>
          </div>
          <div className="flex items-start justify-between">
            <span className="font-medium ">{develop.property_name} </span>
            {/* <div className="p-2 rounded text-sm bg-[#F0EEFF] text-[#6C5DD3]">
              {formatNumberToNaira(develop.current_price)}{" "}
            </div> */}
          </div>
        </div>
      </div>
      <div className="flex gap-[14px] items-center">
        <Button
          className="!h-6 p-1 pl-3 pr-3 text-xs w-[63px] bg-error  text-white"
          onClick={() => openDeleteModal(develop)}
        >
          Delete
        </Button>
        <Button
          className="!h-6 p-1 pl-3 pr-3 text-xs w-[63px] bg-white border border-[#666666] text-[#666666]"
          onClick={() => openViewModal(develop)}
        >
          View
        </Button>
        <Button
          className="!h-6 p-1 pl-3 pr-3 text-xs w-[63px] bg-white border border-[#666666] text-[#666666]"
          onClick={() => openEditModal(develop)}
        >
          Edit
        </Button>
      </div>
    </div>
  );
}

export default DevelopCard;
