import { FormEvent, useMemo, useState } from "react";
import { appAxios } from "../../api/axios";
import Card from "../../common/Card/Card";
import LabelInput from "../../common/LabelInput/LabelInput";
import { sendCatchFeedback, sendFeedback } from "../../utils/feedback";
import {
  closeLoadingIndicator,
  openLoadingIndicator,
} from "../../store/slices/loadingIndicator";
import { useFormik } from "formik";
import * as yup from "yup";
import { useAppDispatch } from "../../store/hooks";
import { useNavigate } from "react-router";
import Button from "../../common/Button/Button";
import Dropdown from "../../common/Dropdown/Dropdown";
import { getUserSession } from "../../utils/userSession";
import HouseUnits from "./HouseUnits";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { v4 as uuidv4 } from "uuid";

interface UnitType {
  [id: string]: {
    id: string;
    title: string;
    current_price: number;
    previous_price: number | undefined;
  };
}

function AddDevelopForm() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentUser = getUserSession();

  const formik = useFormik({
    initialValues: {
      developImages: [],
      imagesUploaded: false,
      property_id: "",
      imagePaths: [],
      title: "",
      description: "",
      propertyName: "",
      state: "",
      city: "",
      address: "",
      neighborhood: "",
      estateFeatures: "",
      size: "",
      currentPrice: 0,
      previousPrice: undefined,
      type: "",
      discount: undefined,
      units: [],
      format: "",
      propertyType: "",
    },
    onSubmit: () => {
      submitValues();
    },
    validationSchema: yup.object({
      title: yup.string().required("Required"),
      property_id: yup.string().required("Required"),
      propertyName: yup.string().required("Required"),
      state: yup.string().required("Required"),
      city: yup.string().required("Required"),
      address: yup.string().required("Required"),
      neighborhood: yup.string().required("Required"),
      estateFeatures: yup.string().required("Required"),
      size: yup.string().required("Required"),
      currentPrice: yup.number().required("Required"),
      format: yup.string().required("Required"),
    }),
  });

  const randomID = useMemo(() => uuidv4(), []);

  const [units, setUnits] = useState<UnitType>({
    [randomID]: {
      id: randomID,
      title: "",
      current_price: 0,
      previous_price: undefined,
    },
  });

  const uploadImages = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!formik.values.developImages.length) {
      return sendFeedback("Select at least one image", "error");
    }

    try {
      dispatch(openLoadingIndicator({ text: "Uploading" }));

      const formData = new FormData();

      // append images
      formik.values.developImages.map((file) => formData.append("files", file));

      const response = await appAxios.post("/upload/files", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: currentUser
            ? "Bearer " + currentUser?.token?.token
            : null,
        },
      });

      sendFeedback(response.data?.status, "success");
      formik.setFieldValue("imagePaths", response.data?.data?.filePaths);
      formik.setFieldValue("imagesUploaded", true);

      document.getElementById("details")?.scrollIntoView();
    } catch (error) {
      sendCatchFeedback(error);
    }
    dispatch(closeLoadingIndicator());
  };

  const submitValues = async () => {
    let unitError = Object.values(units)?.find(
      (unit) => unit?.title === "" || unit?.current_price?.toString() === ""
    );

    if (unitError && formik.values.type === "house") {
      return sendFeedback(
        "Title and current price are required for each house unit",
        "error"
      );
    }

    const data = {
      title: formik.values.title,
      description: formik.values.description,
      property_id: formik.values.property_id,
      property_name: formik.values.propertyName,
      address: formik.values.address,
      city: formik.values.city,
      state: formik.values.state,
      neighborhood: formik.values.neighborhood
        .split(",")
        ?.map((element) => element?.trim()),
      estate_features: formik.values.estateFeatures
        .split(",")
        ?.map((element) => element?.trim()),
      size: formik.values.size,
      previous_price: formik.values.previousPrice
        ? Number(formik.values.previousPrice)
        : undefined,
      current_price: formik.values.currentPrice,
      type: formik.values.type,
      format: formik.values.format,
      discount: formik.values.discount
        ? Number(formik.values.discount)
        : undefined,
      images: formik.values.imagePaths,
      units: formik.values.type === "house" ? Object.values(units) : undefined,
    };

    dispatch(openLoadingIndicator({ text: "Storing Develop" }));
    try {
      const response = await appAxios.post("/develops", data, {
        headers: {
          Authorization: currentUser
            ? "Bearer " + currentUser?.token?.token
            : null,
        },
      });
      sendFeedback(response.data?.status, "success");

      navigate("/develops");
    } catch (error) {
      sendCatchFeedback(error);
    }
    dispatch(closeLoadingIndicator());
  };

  return (
    <>
      <div
        style={{
          pointerEvents: !formik.values.imagesUploaded ? "auto" : "none",
          opacity: !formik.values.imagesUploaded ? 1 : 0.1,
        }}
      >
        <Card className="w-full min-w-full mb-20">
          <form onSubmit={(e) => uploadImages(e)}>
            <h1 className="text-2xl font-bold text-center mb-10 text-black">
              Upload Images
            </h1>

            <div className="flex flex-col gap-2 mb-5">
              <label htmlFor="developImages" className="text-black">
                Select images for the develop
              </label>
              <input
                type="file"
                name="developImages"
                id="developImages"
                multiple
                className="border-lightGrey border-2 text-black"
                accept="image/x-png,image/jpeg"
                onChange={(e: any) => {
                  const file = Array.prototype.slice.call(e.target.files);
                  formik.setFieldValue("developImages", file);
                }}
              />
            </div>
            <Button type="submit">Upload Images</Button>
          </form>
        </Card>
      </div>

      <div
        style={{
          pointerEvents: formik.values.imagesUploaded ? "auto" : "none",
          opacity: formik.values.imagesUploaded ? 1 : 0.1,
        }}
        id="details"
      >
        <Card className="w-full min-w-full">
          <form onSubmit={formik.handleSubmit}>
            <h1 className="text-2xl font-bold text-center mb-10 text-black">
              Develop Details
            </h1>
            <LabelInput
              formik={formik}
              name="title"
              label="Title"
              className="mb-5"
            />
            <LabelInput
              formik={formik}
              name="property_id"
              label="Property ID"
              className="mb-5"
            />
            <LabelInput
              formik={formik}
              name="description"
              label="Description"
              className="mb-5"
            />

            <LabelInput
              formik={formik}
              name="propertyName"
              label="Property Name"
              className="mb-5"
            />
            <Dropdown
              values={[
                {
                  label: "Prime",
                  value: "prime",
                },
                {
                  label: "Luxury",
                  value: "luxury",
                },
              ]}
              label="Format"
              name="format"
              defaultValue={formik.values.format}
              formik={formik}
              className="mb-5"
            />
            <Dropdown
              values={[
                {
                  label: "House",
                  value: "house",
                },
                {
                  label: "Land",
                  value: "land",
                },
              ]}
              label="Type"
              name="type"
              defaultValue={{
                label: formik.values.type,
                value: formik.values.type,
              }}
              formik={formik}
              className="mb-5"
            />

            {formik.values.type === "house" && (
              <HouseUnits units={units} setUnits={setUnits} />
            )}
            <LabelInput
              formik={formik}
              name="size"
              label="Size"
              className="mb-5"
            />
            <LabelInput
              formik={formik}
              name="address"
              label="Address"
              className="mb-5"
            />
            <LabelInput
              formik={formik}
              name="city"
              label="City"
              className="mb-5"
            />
            <LabelInput
              formik={formik}
              name="state"
              label="State"
              className="mb-5"
            />
            <Dropdown
              values={[
                {
                  label: "Residencial",
                  value: "residencial",
                },
                {
                  label: "Commercial",
                  value: "commercial",
                },
                {
                  label: "Both",
                  value: "both",
                },
              ]}
              label="Property Type"
              name="propertyType"
              defaultValue={formik.values.propertyType}
              formik={formik}
              className="mb-5"
            />
            <LabelInput
              formik={formik}
              name="currentPrice"
              label="Current Price"
              type="number"
              className="mb-5"
            />
            <LabelInput
              formik={formik}
              name="previousPrice"
              label="Previous Price"
              type="number"
              className="mb-5"
            />
            <LabelInput
              formik={formik}
              name="discount"
              label="Discount"
              type="number"
              className="mb-5"
            />

            <LabelInput
              formik={formik}
              name="neighborhood"
              label="Neighborhood"
              className="mb-5"
              hint="Separate items by comma"
            />
            <LabelInput
              formik={formik}
              name="estateFeatures"
              label="Estate Features"
              className="mb-10"
              hint="Separate items by comma"
            />

            <Button type="submit">Add Develop</Button>
          </form>
        </Card>
      </div>
    </>
  );
}

export default AddDevelopForm;
