import Button from "../../../common/Button/Button";
import LocationImage from "../../../assets/icons/location.png";
import { formatNumberToNaira } from "../../../utils/stringManipulations";
import { GrowType } from "../../../types";

function DevelopPropertyCard({
  developProperty,
  openDeleteModal,
  openViewModal,
  openEditModal,
}: {
  developProperty: GrowType;
  openDeleteModal: (d: GrowType) => void;
  openViewModal: (d: GrowType) => void;
  openEditModal: (d: GrowType) => void;
}) {
  return (
    <div className="flex flex-col items-stretch gap-2">
      <img
        src={developProperty.images[0]}
        alt="Grow"
        className="w-full h-[186px] rounded-md object-cover"
      />
      <div className="flex flex-col gap-5">
        <div className="flex flex-col gap-2">
          <div className="flex items-start justify-between">
            <span className="font-medium ">{developProperty.title} </span>
            <div className="p-2 rounded text-sm bg-[#F0EEFF] text-[#6C5DD3]">
              {formatNumberToNaira(developProperty.current_price)}{" "}
            </div>
          </div>
          <div className="flex items-center gap-2 mb-[17px]">
            <img src={LocationImage} alt="Location" />
            <span className="line-clamp  text-sm">
              {developProperty.state}{" "}
            </span>
          </div>
        </div>
      </div>
      <div className="flex gap-[14px] items-center">
        <Button
          className="!h-6 p-1 pl-3 pr-3 text-xs w-[63px] bg-error  text-white"
          onClick={() => openDeleteModal(developProperty)}
        >
          Delete
        </Button>
        <Button
          className="!h-6 p-1 pl-3 pr-3 text-xs w-[63px] bg-white border border-[#666666] text-[#666666]"
          onClick={() => openViewModal(developProperty)}
        >
          View
        </Button>
        <Button
          className="!h-6 p-1 pl-3 pr-3 text-xs w-[63px] bg-white border border-[#666666] text-[#666666]"
          onClick={() => openEditModal(developProperty)}
        >
          Edit
        </Button>
      </div>
    </div>
  );
}

export default DevelopPropertyCard;
